// everything should be a function, even if it's not necessary,
import dayjs from 'dayjs'
// just to keep it consistent
// avoid adding query-strings unless it's required

export const useEndpoints = {
  // baseURL: process.env.VUE_APP_BASE_APP_URL + '/dashboard',
  baseURL: () => process.env.VUE_APP_BASE_APP_URL,

  auth: {
    // login: () => '/dashboard/admin-login/',
    login: () => '/dashboard/admin-login-jwt/',
    resetAccessToken: () => '/dashboard/token/refresh/',
    activate: () => '/dashboard/org-users/activate/',
    updatePassword: () => '/dashboard/org-users/update-password/',
    userInfo: () => `/dashboard/user-info/`,
    // post
    resetPassword: () => `/dashboard/request-reset-password/`,
    // get
    permissionActions: () => `/dashboard/permissions/available-actions/`,
  },
  notification: {
    // get
    index: () => '/dashboard/notifications/',
  },

  user: {
    logs: (userId = 'uuid') => `/dashboard/request-logs/user/${userId}/`,
    agent: {
      // get
      index: () => '/dashboard/agents/',
      // get
      topupHistory: (id) => `/dashboard/agents/${id}/topup-history/`,
      // get
      incomingHistory: (id) => `/dashboard/agents/${id}/incoming-history/`,
      // post
      balanceTransfer: (id) => `/dashboard/agents/${id}/transfer-balance/`,
      //post
      exchangeRate: () => '/dashboard/get-exchange-rate/',
    },

    franchise: {
      // get
      index: () => '/dashboard/franchises/',
      // post
      create: () => '/dashboard/franchises/',
      // patch
      update: (id) => `/dashboard/franchises/${id}/`,
    },
    p2pOwners: {
      // get
      index: () => '/dashboard/p2p-owners/',
      // get
      dropdown: () => '/dashboard/p2p-owners/?dropdown',
    },
    rentStationOwner: {
      // get
      index: () => '/dashboard/rent-station-owners/',
    },

    operator: {
      // get
      index: () => '/dashboard/operators/',
      // get
      single: (id) => `/dashboard/org-users/${id}/`,
      // post
      create: () => `/dashboard/operators/`,
      // patch
      update: (id) => `/dashboard/operators/${id}/`,
      // put
      updateStatus: (id) => `/org-users/${id}/update-status/`,
    },

    org: {
      // get
      index: () => '/dashboard/org-users/',

      // post
      create: () => '/dashboard/org-users/',
      // patch
      update: (orgUserId) => `/dashboard/org-users/${orgUserId}/`,

      //delete
      forceLogout: (orgUserId) =>
        `/dashboard/org-users/${orgUserId}/force-logout/`,
      // get
      details: (orgUserId) => `/dashboard/org-users/${orgUserId}/`,

      payment_create: (orgUserId) =>
        `/dashboard/org-users/${orgUserId}/payment-info/`,
      document_create: (orgUserId) =>
        `/dashboard/org-users/${orgUserId}/document-info/`,
      address_create: (orgUserId) =>
        `/dashboard/org-users/${orgUserId}/address-info/`,

      // post
      verifyDocs: (orgUserId) =>
        `/dashboard/org-users/${orgUserId}/document-info/`,

      // post
      activate: () => `/dashboard/org-users/activate/`,
      // put
      updateStatus: (orgUserId) =>
        `/dashboard/org-users/${orgUserId}/update-status/`,
      // get
      pointIndex: (riderId) =>
        `/dashboard/org-users/${riderId}/behavior-points/`,

      // post
      changePassword: (orgUserId) =>
        `/dashboard/org-users/${orgUserId}/update-password/`,
      // post
      resetPassword: (orgUserId) =>
        `/dashboard/org-users/${orgUserId}/set-password/`,
      //post
      resetUserEmail: () => `/dashboard/reset-email/`,
      //post
      resetUserEmailVerify: () => `/dashboard/reset-email-verify/ `,

      //get
      checkEmailExistence: (email) => `/dashboard/email-exists/?email=${email}`,
      //get
      checkPhoneNumberExistence: (phoneNumber) =>
        `/dashboard/phone-number-exists/?phone_number=${phoneNumber}`,
    },
  },
  logs: {
    // get
    smsLogsIndex: () => '/dashboard/request-logs/sms/',
  },

  rider: {
    // get
    index: () => '/dashboard/riders/',
    // get
    single: (riderId) => `/dashboard/riders/${riderId}/`,
    //patch
    update: (riderId) => `/dashboard/rider-profile/${riderId}/`,
    // get
    paymentInfo: (riderId) => `/dashboard/riders/${riderId}/payment-info/`,
    // get
    paymentLogs: (riderId) =>
      `/dashboard/request-logs/user-payment/${riderId}/`,
    // post
    balanceAdjustment: (riderId) => `/dashboard/riders/${riderId}/`,
    // get
    balanceAdjustmentLogs: (riderId) =>
      `/dashboard/riders/${riderId}/balance-adjustment-records/`,
    // get
    logs: (riderId = 'uuid') => `/dashboard/request-logs/rider/${riderId}/`,

    status: {
      forceSync: (riderId) => `/dashboard/user-status-sync/${riderId}/`,
    },
    //get,post
    customNotifications: () => `/dashboard/custom-notifications/`,
    //post
    addDoc: () => `/dashboard/upload-identity-card/`,
    //patch
    deleteRequest: (riderId) => `/dashboard/riders/${riderId}/delete-request/`,
    //get
    deletedRidersIndex: () => `/dashboard/deleted-riders/`,
    //patch
    restoreDeletedRider: (riderId) =>
      `/dashboard/deleted-riders/${riderId}/restore/`,
    //patch
    changeTestRiderStatus: (riderId) => `/dashboard/users/${riderId}/`,
    rfid: {
      //post
      create: () => `/dashboard/user-rfid/`,
      //patch
      update: (id) => `/dashboard/user-rfid/${id}/`,
      //get
      details: (id) => `/dashboard/user-rfid/${id}/`,
      //delete
      delete: (id) => `/dashboard/user-rfid/${id}/`,
    },
  },

  organization: {
    // get
    index: () => '/dashboard/organizations/',
    // post
    create: () => '/dashboard/organizations/',
    // get
    details: (orgId) => `/dashboard/organizations/${orgId}/`,
    // patch
    update: (orgId) => `/dashboard/organizations/${orgId}/`,
    //post
    blockOrg: () => `/dashboard/block-sms-for-organization/`,
    unblockOrg: () => `/dashboard/unblock-sms-for-organization/`,

    card: {
      // post
      add: () => `/dashboard/organizations/subscription-payment-method/add/`,
    },
    subscriptionInvoice: {
      // get
      index: ({ unpaidOnly = false, paidOnly = false, orgId = '' } = {}) => {
        const base = `/dashboard/organizations/subscription-invoices/?organization=${orgId}`

        if (unpaidOnly && paidOnly) {
          return base
        }
        if (unpaidOnly) {
          return `${base}&status=U`
        }
        if (paidOnly) {
          return `${base}&status=P`
        }
        return base
      },
      // get
      details: (invoiceId) =>
        `/dashboard/organizations/subscription-invoices/${invoiceId}/`,
      // post
      triggerPayment: (invoiceId) =>
        `/dashboard/organizations/subscription-invoices/${invoiceId}/trigger-payment/`,
      // post
      markAsPaid: (invoiceId) =>
        `/dashboard/organizations/subscription-invoices/${invoiceId}/mark-paid/`,
      //patch
      updateInvoice: (invoiceId) =>
        `/dashboard/organizations/subscription-invoices/${invoiceId}/`,
      //post
      createInvoiceItem: () =>
        `/dashboard/organizations/subscription-invoice-items/`,
      //patch
      updateInvoiceItem: (invoiceItemId) =>
        `/dashboard/organizations/subscription-invoice-items/${invoiceItemId}/`,
      //delete
      deleteInvoiceItem: (invoiceItemId) =>
        `/dashboard/organizations/subscription-invoice-items/${invoiceItemId}/`,
      //get
      getBankDetails: () => `/dashboard/bank-details/`,
      //get
      getInvoiceEmailInfo: (invoiceId) =>
        `/dashboard/organizations/subscription-invoices/${invoiceId}/invoice-email/`,
      //put
      sendInvoiceEmailInfo: (invoiceId) =>
        `/dashboard/organizations/subscription-invoices/${invoiceId}/invoice-email/`,
    },

    vehicleTypeSettings: {
      // get
      index: (orgId) =>
        `/dashboard/organizations/${orgId}/vehicle-iot-type-settings/`,
      // post
      create: (orgId) =>
        `/dashboard/organizations/${orgId}/vehicle-iot-type-settings/`,
      // patch
      update: (configId) =>
        `/dashboard/organizations/vehicle-iot-type-settings/${configId}/`,
    },
    maintenanceSettings: {
      // get
      details: (orgId) =>
        `/dashboard/organizations/${orgId}/maintenance-settings/`,
      // patch
      update: (orgId) =>
        `/dashboard/organizations/${orgId}/maintenance-settings/`,
      // get
      unavailabilityTypeChoices: () =>
        `/dashboard/service-unavailability-type-choices/`,
    },
    additionalMessageSettings: {
      // get
      index: () => `/dashboard/organization-messages/`,
      // post
      create: () => `/dashboard/organization-messages/`,
      // patch
      update: (messageId) => `/dashboard/organization-messages/${messageId}/`,
      // patch
      delete: (messageId) => `/dashboard/organization-messages/${messageId}/`,
      //get
      choices: () => `/dashboard/organization-message-type-choices/`,
    },
  },
  operationPrimer: {
    inspectionRules: {
      // get
      index: (orgId) => `/dashboard/inspection/${orgId}/inspection-rules/`,
      // post
      create: (orgId) => `/dashboard/inspection/${orgId}/inspection-rules/`,
      // patch
      update: (ruleId) => `/dashboard/inspection/inspection-rules/${ruleId}/`,
      // delete
      delete: (ruleId) => `/dashboard/inspection/inspection-rules/${ruleId}/`,
    },
    vehicleInspectionPart: {
      //get
      index: () => `/dashboard/organization-vehicle-parts/`,
      //post
      create: () => `/dashboard/organization-vehicle-parts/`,
      //patch
      update: (partId) => `/dashboard/organization-vehicle-parts/${partId}/`,
      //delete
      delete: (partId) => `/dashboard/organization-vehicle-parts/${partId}/`,
      //get
      details: (partId) => `/dashboard/organization-vehicle-parts/${partId}/`,
    },
    vehicleInspectionSubPart: {
      //get
      index: () => `/dashboard/organization-vehicle-sub-parts/`,
      //post
      create: () => `/dashboard/organization-vehicle-sub-parts/`,
      //patch
      update: (subPartId) =>
        `/dashboard/organization-vehicle-sub-parts/${subPartId}/`,
      //delete
      delete: (subPartId) =>
        `/dashboard/organization-vehicle-sub-parts/${subPartId}/`,
      //get
      details: (subPartId) =>
        `/dashboard/organization-vehicle-sub-parts/${subPartId}/`,
    },
    vehicleInspectionSubPartRepairOption: {
      //get
      index: () => `/dashboard/organization-vehicle-sub-part-repair-options/`,
      //post
      create: () => `/dashboard/organization-vehicle-sub-part-repair-options/`,
      //patch
      update: (subPartOptionId) =>
        `/dashboard/organization-vehicle-sub-part-repair-options/${subPartOptionId}/`,
      //delete
      delete: (subPartOptionId) =>
        `/dashboard/organization-vehicle-sub-part-repair-options/${subPartOptionId}/`,
      //get
      details: (subPartOptionId) =>
        `/dashboard/organization-vehicle-sub-part-repair-options/${subPartOptionId}/`,
    },
    inspectionAction: {
      //post
      start: () => `/dashboard/inspection/start/`,
      //patch
      cancel: (inspectionId) => `/dashboard/inspection/${inspectionId}/cancel/`,
      //patch
      submit: (inspectionId) =>
        `/dashboard/inspection/${inspectionId}/submit-inspection/`,
      //get
      status: (inspectionId) =>
        `/dashboard/inspection/${inspectionId}/check-status/`,
      //patch
      updateSubpart: (subPartId) =>
        `/dashboard/inspection/sub-parts/${subPartId}/inspect/`,
    },
    repairAction: {
      //get
      detail: (vehicleId) =>
        `/dashboard/inspection/repair/?vehicle_id=${vehicleId}`,
      //patch
      submit: (inspectionId) =>
        `/dashboard/inspection/${inspectionId}/submit-repair/`,
      status: (inspectionId) =>
        `/dashboard/inspection/${inspectionId}/check-status/`,
      //patch
      //patch
      updateRepairOption: (subPartId) =>
        `/dashboard/inspection/sub-parts/${subPartId}/repair/`,
      logs: (vehicleId) =>
        `/dashboard/inspection/vehicles/${vehicleId}/repair-logs/`,
    },
  },
  manufacturers: {
    // get
    index: () => '/dashboard/lock-manufacturers/',
    // post
    create: () => '/dashboard/lock-manufacturers/',
    // patch
    update: (manufacturerId) =>
      `/dashboard/lock-manufacturers/${manufacturerId}`,
  },

  // todo: move to user?
  identityCard: {
    update: (id) => `/dashboard/identity-cards/${id}/`,
  },

  // todo: convert to arrow functions
  analytics: {
    gauge_chart: () => '/dashboard/analytics/gauge-chart/',
    bar_chart: () => '/dashboard/analytics/bar-chart/',
    map_chart: () => '/dashboard/analytics/map-chart/',
    line_chart: () => '/dashboard/analytics/line-chart/',
    activity_chart: () => '/dashboard/analytics/activity-chart/',
    trip_heatmap: () => '/dashboard/analytics/demand-heatmap/',
    areaChart: ({
      type = 'user',
      dateRange = {},
      fleetId = '',
      orgId = '',
    } = {}) => {
      let { start, end } = dateRange

      if (!start || !end) {
        start = dayjs()
          .subtract(1, 'week')
          .format('YYYY-MM-DD')
        end = dayjs().format('YYYY-MM-DD')
      }

      const qs = new URLSearchParams()
      qs.append('type', type)
      qs.append('end_date', end)
      qs.append('start_date', start)

      orgId && qs.append('organization', orgId)
      fleetId && qs.append('fleet', fleetId)

      return `/dashboard/analytics/line-chart/?` + qs.toString()
    },
    iotAreaChart: ({ dateRange = {}, fleetId = '', orgId = '' } = {}) => {
      let { start, end } = dateRange

      if (!start || !end) {
        start = dayjs()
          .subtract(1, 'week')
          .format('YYYY-MM-DD')
        end = dayjs().format('YYYY-MM-DD')
      }

      const qs = new URLSearchParams()
      qs.append('end_date', end)
      qs.append('start_date', start)

      orgId && qs.append('organization', orgId)
      fleetId && qs.append('fleet', fleetId)

      return `/dashboard/analytics/line-chart/iot-data/?` + qs.toString()
    },
    iotUnlockDataByIotCategory: () =>
      '/dashboard/analytics/iot-unlock-data-by-iot-category/',
    iotLockDataByIotCategory: () =>
      '/dashboard/analytics/iot-lock-data-by-iot-category/',
  },

  pricingPlan: {
    // get
    index: () => '/dashboard/pricing-plans/',
    // get
    details: (id) => `/dashboard/pricing-plans/${id}/`,
    // post
    create: () => '/dashboard/pricing-plans/',
    // patch
    update: (id) => `/dashboard/pricing-plans/${id}/`,
    //get
    getRentPackageTypeChoice: () => `/dashboard/trip-package-type-choices/`,
    // get
    rentPackages: (pId) => `/dashboard/pricing-plan/${pId}/trip-packages/`,
    //post
    createRentPackage: () => `/dashboard/trip-packages/`,
    //post
    updateRentPackage: (rentPackageId) =>
      `/dashboard/trip-packages/${rentPackageId}/`,
    //delete
    deleteRentPackage: (rentPackageId) =>
      `/dashboard/trip-packages/${rentPackageId}/`,
  },
  rentPricingPlan: {
    getRentPackageTypeChoice: () => `/dashboard/rent-package-type-choices/`,
    // get
    rentPackages: (pId) => `/dashboard/pricing-plan/${pId}/rent-packages/`,
    //post
    createRentPackage: () => `/dashboard/rent-packages/`,
    //post
    updateRentPackage: (rentPackageId) =>
      `/dashboard/rent-packages/${rentPackageId}/`,
    //delete
    deleteRentPackage: (rentPackageId) =>
      `/dashboard/rent-packages/${rentPackageId}/`,

    //get
    getRentAddonsChoice: () => `/dashboard/rent-add-on-type-choices/`,
    // get
    rentAddonsList: (pId) => `/dashboard/pricing-plan/${pId}/rent-add-ons/`,
    //post
    createRentAddons: () => `/dashboard/rent-add-ons/`,
    //post
    updateRentAddons: (rentAddonsId) =>
      `/dashboard/rent-add-ons/${rentAddonsId}/`,
    //delete
    deleteRentAddons: (rentAddonsId) =>
      `/dashboard/rent-add-ons/${rentAddonsId}/`,
  },

  // todo: to func
  subscriptionPlan: {
    // get
    index: '/dashboard/subscriptions/',
    // post
    create: '/dashboard/subscriptions/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/subscriptions/${id}/`
    },
  },

  // todo: toFunc
  taxPlan: {
    // get
    index: '/dashboard/tax-plans/',
    // post
    create: '/dashboard/tax-plans/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/tax-plans/${id}/`
    },
  },

  // transactions
  invoice: {
    // get
    index: () => '/dashboard/invoices/',
    indexOfRevenue: () => `/dashboard/revenue-invoices/?`,
    indexOfPayment: () => `/dashboard/payment-invoices/?`,
    indexOfTriggerPayment: () => `/dashboard/invoices/?neg_balance_invoices&`,
    triggerNegativeBalanceResolver: (tripId) =>
      `/dashboard/invoices/${tripId}/trigger-neg-balance-resolver/`,
    // get
    details: (tripId) => {
      return `/dashboard/invoices/?trip=${tripId}&`
    },
    // post
    create: () => '/dashboard/invoices/',
    // patch
    update: (invoiceId) => `/dashboard/invoices/${invoiceId}/`,
    // delete
    delete: (invoiceId) => `/dashboard/invoices/${invoiceId}/`,
    // post
    refund: (id = 'invoice-uuid') => `/dashboard/invoices/${id}/refund/`,
    // post
    policyPenalty: (tripId, policyId) =>
      `/dashboard/penalize-trip-for-policy/${tripId}/policy/${policyId}/`,
  },
  refundApplication: {
    //get
    index: () => '/dashboard/refund-applications/',
    //patch
    update: (id) => `/dashboard/refund-applications/${id}/`,
  },

  fleet: {
    // get
    index: () => '/dashboard/fleets/',
    // get
    indexOfStaff: (userId) => `/dashboard/fleets/?org_user=${userId}`,
    // post
    create: () => '/dashboard/fleets/',
    // patch
    update: (fleetId) => `/dashboard/fleets/${fleetId}/`,
    // get
    details: (fleetId) => `/dashboard/fleets/${fleetId}/`,
    // delete
    delete: (fleetId) => `/dashboard/fleets/${fleetId}/`,
    //get penalty list
    penaltyIndex: (fleetId) =>
      `/dashboard/fleet-policy-penalty-list/${fleetId}/`,
    //update penalties
    penaltiesUpdate: (penaltyId) =>
      `/dashboard/fleet-policy-penalties/${penaltyId}/`,
    operator: {
      // get & patch
      charging: (fleetId) =>
        `/dashboard/fleets/${fleetId}/operator-task-charging-settings/`,
      // get & patch
      rebalance: (fleetId) =>
        `/dashboard/fleets/${fleetId}/operator-task-rebalancing-settings/`,
    },
  },

  lock: {
    // get
    index: () => '/dashboard/locks/',
    // post
    create: () => '/dashboard/locks/',
    // patch
    update: (id) => `/dashboard/locks/${id}/`,
  },

  vehicle: {
    // get
    index: () => '/dashboard/vehicles/',
    // get || deprecated -> pass query string instead
    indexOfFranchise: (userId = 'uuid') => {
      return `/dashboard/vehicles/?franchise_user=${userId}&`
    },
    // get
    details: (id) => `/dashboard/vehicles/${id}/`,
    // post
    create: () => '/dashboard/vehicles/',
    // delete
    delete: (vehicleId) => `/dashboard/vehicles/${vehicleId}/`,
    // patch
    update: (id) => `/dashboard/vehicles/${id}/`,
    // patch
    updateGeneralFLags: (id) => `/dashboard/vehicles/${id}/general-flags/`,
    // patch
    updateOperationalFLags: (id) =>
      `/dashboard/vehicles/${id}/operational-flags/`,
    // patch
    updateControlFLags: (id) => `/dashboard/vehicles/${id}/control-flags/`,
    // patch
    updateStatusFLags: (id) => `/dashboard/vehicles/${id}/status-flags/`,
    // patch
    updateVehicleIOTMode: (id) => `/dashboard/vehicles/${id}/set-iot-mode/`,

    // patch
    syncHeartbeatFrequency: (id) =>
      `/dashboard/vehicles/${id}/sync-heartbeat-frequency/`,

    // post
    sendControlFLagsBySMS: (id) =>
      `/dashboard/vehicles/${id}/control-flags-sms/`,
    // post
    ringAlarm: (id = 'uuid') => `/dashboard/vehicles/${id}/ring-alarm/`,
    triggerRing: (id = 'uuid') => `/dashboard/vehicles/${id}/ring-alarm/`,
    // post
    bulkUpdateStatus: () => `/dashboard/vehicles/bulk-status-update/`,
    bulkDelete: () => `/dashboard/vehicles/bulk-delete/`,
    // get
    // logs: (id = 'uuid') => `/dashboard/vehicles/${id}/entity-logs/`,
    logs: (id = 'uuid') => `/dashboard/request-logs/vehicle/${id}/`,

    // get
    powerLevel: (vehicleId, startDate, endDate) =>
      `/dashboard/vehicles/${vehicleId}/power-level-chart/?start_date=${startDate}&end_date=${endDate}`,
    // get
    healthChart: (vehicleId, startDate, endDate) =>
      `/dashboard/vehicles/${vehicleId}/health-charts/?start_date=${startDate}&end_date=${endDate}`,
    // get
    lockConfigurations: (vehicleId) =>
      `/dashboard/vehicles/${vehicleId}/lock-configurations/`,
    //patch
    scooterExtras: (scooterExtraId = 'uuid') => {
      return `/dashboard/vehicles-scooter-extras/${scooterExtraId}/`
    },
    // post
    setStartMode: (vehicleId) => `/dashboard/vehicles/${vehicleId}/start-mode/`,
    // post
    hexCommand: (vehicleId) => `/dashboard/vehicles/${vehicleId}/hex-command/`,
    // post
    unlockHelmet: (id = 'uuid') => {
      return `/dashboard/vehicles/${id}/unlock-helmet/`
    },
    // post
    syncHelmetStatus: (id = 'uuid') => {
      return `/dashboard/vehicles/${id}/sync-helmet-status/`
    },
    // vehicle status api
    status: {
      current: (vehicleId) => `/dashboard/vehicles/${vehicleId}/status/`,
      // post
      lock: (vehicleId) => `/dashboard/vehicles/${vehicleId}/lock/`,
      // post
      unlock: (vehicleId) => `/dashboard/vehicles/${vehicleId}/unlock/`,
      // post
      forceLock: (vehicleImei, vehicleType) =>
        `/dashboard/${vehicleType}/${vehicleImei}/force-lock/`,
      // post
      forceUnlock: (vehicleImei, vehicleType) =>
        `/dashboard/${vehicleType}/${vehicleImei}/force-unlock/`,
      // post
      forceSync: (vehicleId) =>
        `/dashboard/vehicles/${vehicleId}/force-status-sync/`,
    },
    iotLogs: {
      splunk: (vehicleId) =>
        `/dashboard/vehicles/${vehicleId}/iot-splunk-logs/`,
      heartbeat: (vehicleId) =>
        `/dashboard/vehicles/${vehicleId}/iot-heartbeat-logs/`,
      position: (vehicleId) =>
        `/dashboard/vehicles/${vehicleId}/iot-position-logs/`,
      commands: (vehicleId) => `/dashboard/vehicles/${vehicleId}/iot-cmd-logs/`,
      all: (vehicleId) => `/dashboard/vehicles/${vehicleId}/iot-logs/`,
    },
    buyer: {
      assignOwner: (vehicleId) =>
        `/dashboard/vehicles-assigned-owner/${vehicleId}/`,
    },
    swapBatteryWorkflow: {
      //post
      start: () => `/dashboard/workflow/start/`,
      //patch
      execute: (workflowId) => `/dashboard/workflow/${workflowId}/execute/`,
      //patch
      complete: (workflowId) => `/dashboard/workflow/${workflowId}/complete/`,
    },
    chargingDockAdapter: {
      //post
      create: () => `/dashboard/charging-adapters/`,
      //delete
      delete: (id) => `/dashboard/charging-adapters/${id}/`,
      //patch
      undock: (id) => `/dashboard/vehicles/${id}/undock/`,
      //get
      dockingStatus: (id) => `/dashboard/vehicles/${id}/docking-status/`,
      //
    },
    chargingDock: {
      //post
      sync: () => `/dashboard/sync-charging-dock-and-ports/`,
      //get
      index: () => `/dashboard/charging-docks/`,
      //delete
      delete: (id) => `/dashboard/charging-docks/${id}/delete/`,
      //get
      portIndex: (id) => `/dashboard/charging-docks/${id}/ports/`,
    },
  },

  singleScooter: {
    // get
    index: '/dashboard/organizations/',
    // get
    single_lock: (id = 'uuid') => {
      return `/dashboard/locks/${id}/`
    },
    single_vehicle: (id = 'uuid') => {
      return `/dashboard/vehicles/${id}/`
    },

    iot: {
      // assigned & unassigned dropdown
      dropdown: () => `/dashboard/locks/?dropdown`,
      // unassigned dropdown
      unassigned: () => `/dashboard/locks/?dropdown&not_assigned`,
    },

    create: '/dashboard/organizations/',
    create_iot: '/dashboard/locks/',
    update_iot: (id = 'uuid') => {
      return `/dashboard/locks/${id}/`
    },

    create_vehicle: '/dashboard/vehicles/',
    update_vehicle: (id = 'uuid') => {
      return `/dashboard/vehicles/${id}/`
    },
    fleets: '/dashboard/fleets/',
    // patch [edit|add-step-2]
    update: (id = 'uuid') => {
      return `/dashboard/organizations/${id}/`
    },
    iot_manufacture: '/dashboard/lock-manufacturers/',
    vehicle_manufacture: '/dashboard/vehicle-manufacturers/',
    iot_index: '/dashboard/locks/',
    summary: {},
    profile: {},
    // fleets: {
    //   // todo: merge?
    //   // get
    //   index: "/dashboard/fleets/",
    //   create: ""
    // }
  },

  geoFence: {
    parkingArea: {
      // get
      index: () => '/dashboard/parking-areas/',
      // post
      create: () => '/dashboard/parking-areas/',
      // patch
      update: (parkingAreaId) => `/dashboard/parking-areas/${parkingAreaId}/`,
      // delete
      delete: (parkingAreaId) => `/dashboard/parking-areas/${parkingAreaId}/`,
      // get
      details: (parkingAreaId) => `/dashboard/parking-areas/${parkingAreaId}/`,
    },

    restrictedArea: {
      // get
      index: () => '/dashboard/restricted-areas/',
      // post
      create: () => '/dashboard/restricted-areas/',
      // patch
      update: (id) => `/dashboard/restricted-areas/${id}/`,
      // get
      details: (id) => `/dashboard/restricted-areas/${id}/`,
      // delete
      delete: (id) => `/dashboard/restricted-areas/${id}/`,
    },

    serviceArea: {
      // get
      index: () => '/dashboard/service-areas/',
      // post
      create: () => '/dashboard/service-areas/',
      // patch
      update: (sId) => `/dashboard/service-areas/${sId}/`,
      // delete
      delete: (sId) => `/dashboard/service-areas/${sId}/`,
      // get
      details: (sId) => `/dashboard/service-areas/${sId}/`,
      // get
      childIndex: (sId) => `/dashboard/service-areas/${sId}/child-areas/`,
      // patch
      makeDefaults: (sId) => `/dashboard/service-areas/${sId}/swap/`,
    },

    slowSpeedArea: {
      // get
      index: () => '/dashboard/slow-zone-areas/',
      // post
      create: () => '/dashboard/slow-zone-areas/',
      // get
      details: (id = 'uuid') => {
        return `/dashboard/slow-zone-areas/${id}/`
      },
      // patch
      update: (id = 'uuid') => {
        return `/dashboard/slow-zone-areas/${id}/`
      },
      // delete
      delete: (id = 'uuid') => {
        return `/dashboard/slow-zone-areas/${id}/`
      },
    },
    rentStationArea: {
      // get
      index: () => '/dashboard/rent-areas/',
      // post
      create: () => '/dashboard/rent-areas/',
      // patch
      update: (rentStationId) => `/dashboard/rent-areas/${rentStationId}/`,
      // delete
      delete: (rentStationId) => `/dashboard/rent-areas/${rentStationId}/`,
      // get
      details: (rentStationId) => `/dashboard/rent-areas/${rentStationId}/`,
    },
  },

  trip: {
    // get
    index: () => '/dashboard/trips/',
    // post
    create: () => '/dashboard/trips/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/trips/${id}/`
    },
    single: (id = 'uuid') => {
      return `/dashboard/trips/${id}/`
    },
    vehicleTimelineLog: (id = 'uuid') => {
      return `/dashboard/trips/${id}/trip-logs/?log_for=Bike`
    },
    userTimelineLog: (id = 'uuid') => {
      return `/dashboard/trips/${id}/trip-logs/?log_for=User`
    },
    tripRequestLogs: (id = 'uuid') => {
      return `/dashboard/request-logs/trip/${id}/`
    },
    entityLogs: (id = 'uuid') => {
      return `/dashboard/request-logs/${id}/entity-logs/`
    },
    requestLog: (id = 'uuid') => {
      return `/dashboard/request-logs/${id}/`
    },
    iotLog: (id = 'uuid', type = 'action-name') => {
      return `/dashboard/iot-logs/${id}/${type}/`
    },
    actions: {
      cancel: (tripId = 'uuid') => {
        return `/dashboard/trips/${tripId}/cancel/`
      },
      complete: (tripId = 'uuid') => {
        return `/dashboard/trips/${tripId}/complete/`
      },
    },
    getEmailTemplate: (tripId = 'uuid') =>
      `/dashboard/trips/${tripId}/invoice-email/`,
    sendEmail: (tripId = 'uuid') => `/dashboard/trips/${tripId}/invoice-email/`,
  },

  vehicleRental: {
    cancelReasons: () =>
      '/dashboard/rentals/vehicle-rentals/request-cancel-reasons/',
    rejectReasons: () =>
      '/dashboard/rentals/vehicle-rentals/request-reject-reasons/',
    // get
    index: () => '/dashboard/rentals/vehicle-rentals/',
    // post
    create: () => '/dashboard/rentals/vehicle-rentals/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/rentals/vehicle-rentals/${id}/`
    },
    single: (id = 'uuid') => {
      return `/dashboard/rentals/vehicle-rentals/${id}/`
    },
    getRentableVehicles: (id = 'uuid') => {
      return `/dashboard/rentals/vehicle-rentals/${id}/rentable-vehicles/`
    },
    vehicleTimelineLog: (id = 'uuid') => {
      return `/dashboard/rentals/vehicle-rentals/${id}/vehicle-elastic-logs/`
    },
    userTimelineLog: (id = 'uuid') => {
      return `/dashboard/rentals/vehicle-rentals/${id}/rental-elastic-logs/`
    },
    rentalRequestLogs: (id = 'uuid') => {
      return `/dashboard/request-logs/rental/${id}/`
    },

    actions: {
      cancel: (id = 'uuid') => {
        return `/dashboard/rentals/vehicle-rentals/${id}/cancel/`
      },
      complete: (id = 'uuid') => {
        return `/dashboard/rentals/vehicle-rentals/${id}/complete/`
      },
      forceComplete: (id = 'uuid') => {
        return `/dashboard/rentals/vehicle-rentals/${id}/force-complete/`
      },
    },
    getEmailTemplate: (rentId = 'uuid') =>
      `/dashboard/rentals/vehicle-rentals/${rentId}/invoice-email-template/`,
    sendEmail: (rentId = 'uuid') =>
      `/dashboard/rentals/vehicle-rentals/${rentId}/invoice-email/`,
  },

  pass: {
    // get
    index: () => '/dashboard/passes/',
    // post
    create: () => '/dashboard/passes/',
    // patch
    update: (passId) => `/dashboard/passes/${passId}/`,
    // get
    details: (passId) => `/dashboard/passes/${passId}/`,
    //post
    notifyRiders: (passId) => `/dashboard/passes/${passId}/notify-riders/`,
    //get
    passCategoryChoices: () => `/dashboard/passes/category-choices/`,
    //get
    profileTypeChoices: () => `/dashboard/user-type-choices/`,
  },

  promo: {
    // get
    index: () => '/dashboard/promotions/',
    // post
    create: () => '/dashboard/promotions/',
    // patch
    update: (promoId) => `/dashboard/promotions/${promoId}/`,
    // get
    details: (promoId) => `/dashboard/promotions/${promoId}/`,
    // get
    ofUser: (userId) => `/dashboard/promotions/${userId}/user-promotions/`,

    // post
    notifyRiders: (promoId) =>
      `/dashboard/promotions/${promoId}/notify-riders/`,
  },

  paymentGateway: {
    // post [create|org-add-step-3]
    create: () => '/dashboard/payment-gateway-configs/',
    // post [update|org-edit-step-3]
    update: (id = 'id') => {
      return `/dashboard/payment-gateway-configs/${id}/`
    },
  },

  role: {
    // get
    index: () => '/dashboard/roles/',
    // post
    create: () => '/dashboard/roles/',
    // patch
    update: (roleId) => `/dashboard/roles/${roleId}/`,
    // get
    details: (roleId) => `/dashboard/roles/${roleId}/`,
  },

  permission: {
    // get
    index: '/dashboard/permissions/',
    // post
  },

  report: {
    // get
    index: () => '/dashboard/reports/',
    // post
    create: () => '/dashboard/reports/',
    // patch
    update: (reportId) => `/dashboard/reports/${reportId}/`,
    // get
    details: (reportId) => `/dashboard/reports/${reportId}/`,
    // get
    logs: (reportId) => `/dashboard/reports/${reportId}/logs/`,
  },

  // todo: toFunc
  settings: {
    basic: {
      // get
      index: (orgId) => `/dashboard/organizations/${orgId}/basic-settings/`,
      // patch
      update: (orgId) => `/dashboard/organizations/${orgId}/basic-settings/`,
    },

    rent: {
      // get
      index: (orgId) => `/dashboard/organizations/${orgId}/rent-settings/`,
      // patch
      update: (orgId) => `/dashboard/organizations/${orgId}/rent-settings/`,
      //get
      rentDurationModeChoices: () => `/dashboard/rent-duration-mode-choices/`,
      //get
      rentVehicleReturnAreaChoices: () =>
        `/dashboard/rent-vehicle-return-area-policy-choices/`,
      //get
      vehicleRentAssignedPolicyChoices: () =>
        `/dashboard/rent-vehicle-assignment-policy-choices/`,
      //get
      rentTimeExtensionModeChoices: () =>
        `/dashboard/rent-time-extension-mode-choices/`,
    },

    email: {
      // get
      index: (orgId) => `/dashboard/organizations/${orgId}/mail-settings/`,
      // patch
      update: (orgId) => `/dashboard/organizations/${orgId}/mail-settings/`,
    },

    vehicle: {
      // get
      index: (orgId) => `/dashboard/organizations/${orgId}/vehicle-settings/`,
      // patch
      update: (orgId) => `/dashboard/organizations/${orgId}/vehicle-settings/`,
      // get
      ledControlChoices: () =>
        `/dashboard/vehicle-led-color-control-type-choices/`,
      //get
      ledColorChoices: () => `/dashboard/vehicle-led-color-choices/`,
      //get
      chargingPlatformChoices: () => `/dashboard/charging-platform-choices/`,
    },

    event: {
      // get
      index: (orgId) => `/dashboard/organizations/${orgId}/event-settings/`,
      // patch
      update: (eventId) => `/dashboard/event-settings/${eventId}/`,
    },

    general: {
      // get
      index: (orgId) => `/dashboard/organizations/${orgId}/general-settings/`,
      // patch
      update: (orgId) => `/dashboard/organizations/${orgId}/general-settings/`,
      // get
      invoiceServiceChoices: () => `/dashboard/invoicing-service-choices/`,
    },

    point: {
      // get
      index: () => `/dashboard/organization-behavior-points/`,
      // patch
      update: (orgId) => `/dashboard/organization-behavior-points/${orgId}/`,
      // get
      details: (orgId) => `/dashboard/organization-behavior-points/${orgId}/`,
    },

    payment: {
      gateway: {
        // get
        index: () => `/dashboard/payment-gateways/`,
        // patch
        update: function(id = 'uuid') {
          return `/dashboard/payment-gateway-configs/${id}/`
        },
      },
      topup: {
        // get
        index(id = 'uuid') {
          return `/dashboard/organizations/${id}/topup-settings/`
        },
        // patch
        update(id = 'uuid') {
          return `/dashboard/organizations/${id}/topup-settings/`
        },
      },
    },

    operator: {
      charging: {
        // get
        index: function(id = 'uuid') {
          return `/dashboard/organizations/${id}/operator-task-charging-settings/`
        },
        // patch
        update: function(id = 'uuid') {
          return `/dashboard/organizations/${id}/operator-task-charging-settings/`
        },
      },
      rebalancing: {
        // get
        index: function(id = 'uuid') {
          return `/dashboard/organizations/${id}/operator-task-rebalancing-settings/`
        },
        // patch
        update: function(id = 'uuid') {
          return `/dashboard/organizations/${id}/operator-task-rebalancing-settings/`
        },
      },
    },

    businessHour: {
      // get
      index: function(id = 'uuid') {
        return `/dashboard/organizations/${id}/business-hour-settings/`
      },
      // patch
      update: function(id = 'uuid') {
        return `/dashboard/business-hour-settings/${id}/`
      },
    },
    riderApp: {
      // get
      index: function(id = 'uuid') {
        return `/dashboard/organizations/${id}/app-update-settings/`
      },
      // get
      co2SavedUnitChoices: function(id = 'uuid') {
        return `/dashboard/organizations/${id}/co2-saved-unit-choices/`
      },
      // get
      riderWeightUnitChoices: function(id = 'uuid') {
        return `/dashboard/organizations/${id}/co2-saved-unit-choices/`
      },
      // get
      riderWalletBalanceTypeChoices: () =>
        `/dashboard/rider-wallet-balance-type-choices/`,

      // get
      appInstructionSettings: () => `/dashboard/app-instruction-settings/`,

      // patch
      updateAppInstructionSettings: (id = 'uuid') =>
        `/dashboard/app-instruction-settings/${id}/`,
    },
    safety: {
      // get
      index: function(id = 'uuid') {
        return `/dashboard/organizations/${id}/safety-settings/`
      },
      sobrietySchedule: {
        // get
        index: () => {
          return `/dashboard/sobriety-test-hour-settings/`
        },
        // update
        update: (id = 'uuid') => {
          return `/dashboard/sobriety-test-hour-settings/${id}/`
        },
      },
      sobrietyQuiz: {
        // get
        index: () => {
          return `/dashboard/sobriety-test-questions/`
        },
        // get
        details: (id = 'uuid') => {
          return `/dashboard/sobriety-test-questions/${id}/`
        },
        // patch
        update: (id = 'uuid') => {
          return `/dashboard/sobriety-test-questions/${id}/`
        },
        // delete
        delete: (id = 'uuid') => {
          return `/dashboard/sobriety-test-questions/${id}/`
        },
      },
    },
  },

  task: {
    // get
    index: () => '/dashboard/tasks/',
    // post
    create: () => '/dashboard/tasks/bulk-create/',
    // patch
    update: (taskId) => `/dashboard/tasks/${taskId}/`,
    // delete
    delete: (taskId) => `/dashboard/tasks/${taskId}/`,
    // get
    details: (taskId) => `/dashboard/tasks/${taskId}/`,
    // get
    logs: (taskId) => `/dashboard/request-logs/task/${taskId}/`,
  },
  mail: {
    group: {
      // get mail template groups
      index: () => '/dashboard/email-template-groups/',
      // post mail template group
      create: () => '/dashboard/email-template-groups/',
      // patch mail template group
      update: (mailTemplateId) =>
        `/dashboard/email-template-groups/${mailTemplateId}/`,
      // delete mail template group
      delete: (mailTemplateId) =>
        `/dashboard/email-template-groups/${mailTemplateId}/`,
      // get mail template group
      details: (mailTemplateId) =>
        `/dashboard/email-template-groups/${mailTemplateId}/`,
      // get mail template group
      logs: (mailTemplateId) =>
        `/dashboard/email-template-groups/${mailTemplateId}/logs/`,
    },
    template: {
      // get templates
      index: () => '/dashboard/email-templates/',
      // post template
      create: () => '/dashboard/email-templates/',
      // patch template
      update: (templateId) => `/dashboard/email-templates/${templateId}/`,
      // delete template
      delete: (templateId) => `/dashboard/email-templates/${templateId}/`,
      // get template
      details: (templateId) => `/dashboard/email-templates/${templateId}/`,
      // get template
      logs: (templateId) => `/dashboard/email-templates/${templateId}/logs/`,
    },
    placeholder: {
      // get template placeholders
      index: () => '/dashboard/email-template-placeholders/',
      // post template placeholder
      create: () => '/dashboard/email-template-placeholders/',
      // patch template placeholder
      update: (placeholderId) =>
        `/dashboard/email-template-placeholders/${placeholderId}/`,
      // delete template placeholder
      delete: (placeholderId) =>
        `/dashboard/email-template-placeholders/${placeholderId}/`,
      // get template placeholder
      details: (placeholderId) =>
        `/dashboard/email-template-placeholders/${placeholderId}/`,
      // get template placeholder
      logs: (placeholderId) =>
        `/dashboard/email-template-placeholders/${placeholderId}/logs/`,
    },
    detail: {
      // get template details
      index: () => '/dashboard/email-template-details/',
      // post template detail
      create: () => '/dashboard/email-template-details/',
      // patch template detail
      update: (detailId) => `/dashboard/email-template-details/${detailId}/`,
      // delete template detail
      delete: (detailId) => `/dashboard/email-template-details/${detailId}/`,
      // get template detail
      details: (detailId) => `/dashboard/email-template-details/${detailId}/`,
      // get template detail
      logs: (detailId) => `/dashboard/email-template-details/${detailId}/logs/`,
    },
  },

  // -----------------
  // collections
  // -----------------
  // Naming Guide: For Dropdown
  //
  // use plural forms as it supposed to return array of objects
  // use fluent naming for dropdown instead of concise naming.
  // do not use nested object to make it concise
  // use camel case arrow functions
  //
  // e.g. fleets(), unassignedFleets(), unassignedPricingFleets()

  dropdown: {
    organizations: () => '/dashboard/organizations/?dropdown',
    // todo: use plural form
    country: () => '/dashboard/countries/?dropdown',

    // todo: use plural form
    city: (countryId) => {
      let endpoint = '/dashboard/cities/?dropdown'
      countryId && (endpoint += `&country=${countryId}`)
      return endpoint
    },
    // todo: use plural form
    currency: (countryId) => {
      let endpoint = '/dashboard/currencies/?dropdown'
      countryId && (endpoint += `&country=${countryId}`)
      return endpoint
    },
    currencies: () => '/dashboard/currencies/?dropdown',
    timezones: () => '/dashboard/timezones/',
    organizationCurrencies: () => '/dashboard/org-currencies/',
    orgUsers: () => '/dashboard/org-users/?dropdown',
    orgSupportedLanguage: () => '/dashboard/languages/?dropdown',

    subscriptions: () => '/dashboard/subscriptions/?dropdown',

    paymentGateways: () => '/dashboard/payment-gateways/',

    taxPlans: () => '/dashboard/tax-plans/?dropdown',

    // NOTE: use speedLimits()
    speedLimits: () => '/dashboard/speed-limits/',
    serviceAreaSpeedLimits: () => '/dashboard/speed-limits/',

    lockManufacturers: () => `/dashboard/lock-manufacturers/?dropdown`,
    vehicleManufacturers: () => `/dashboard/vehicle-manufacturers/?dropdown`,

    fleets: () => `/dashboard/fleets/?dropdown`,
    unassignedPricingFleets: () =>
      `/dashboard/fleets/?dropdown&unassigned=pricing`,
    unassignedGeoFenceFleets: () => `/dashboard/fleets/?unassigned=geofence`,

    // get
    serviceAreas: () => '/dashboard/service-areas/?dropdown',
    parkingAreas: () => '/dashboard/parking-areas/?dropdown',
    slowSpeedAreas: () => '/dashboard/slow-zone-areas/?dropdown',
    restrictedAreas: () => '/dashboard/restricted-areas/?dropdown',

    locks: () => `/dashboard/locks/?dropdown`,
    unassignedLocks: () => `/dashboard/locks/?dropdown&not_assigned`,

    lockTypes: () => `/dashboard/lock-iots/?dropdown`,
    buyerList: () => `/dashboard/marketplace/buyer-list-dropdown/`,
    marketplaceAllBuyerList: () => `/dashboard/marketplace/buyer-list/`,

    // vendor :: move to vendor?
    googleMapAddress(place_id, fields, key) {
      let endpoint = 'https://maps.googleapis.com/maps/api/place/details/json?'
      endpoint += `place_id=${place_id}&`
      endpoint += `fields=${fields}&`
      endpoint += `key=${key}&`
      return endpoint
    },

    iot_manufacture: () => '/dashboard/lock-manufacturers/?dropdown',
    vehicle_manufacture: () => '/dashboard/vehicle-manufacturers/?dropdown',
  },

  status: {
    // patch
    user: (id = 'uuid') => {
      return `/dashboard/org-users/${id}/update-status/`
    },
    // patch
    orgUser: (id = 'uuid') => {
      return `/dashboard/org-users/${id}/update-status/`
    },
    // patch
    operator: (id = 'uuid') => {
      return `/dashboard/org-users/${id}/update-status/`
    },
    // patch
    rider: (id = 'uuid') => {
      return `/dashboard/org-users/${id}/update-status/`
    },
    // patch -> @key:status -> @val:A/D/I
    organization: (id = 'uuid') => {
      return `/dashboard/organizations/${id}/`
    },
    // patch -> @key:status -> @val:A/D/I
    vehicle: (id = 'uuid') => {
      return `/dashboard/vehicles/${id}/`
    },
    // patch
    fleet: (id = 'uuid') => {
      return `/dashboard/fleets/${id}/`
    },
  },

  note: {
    // valid entity names: bike, organization...
    // get
    index: (entityId, entityName) =>
      `/dashboard/entity-notes/${entityName}/${entityId}/`,
    // post
    create: (entityId, entityName) =>
      `/dashboard/entity-notes/${entityName}/${entityId}/`,
    // patch
    update: (entityId, entityName) =>
      `/dashboard/entity-notes/${entityName}/${entityId}/`,
    // delete
    delete: (noteId) => `/dashboard/entity-notes/${noteId}/`,
  },
  export: {
    //post
    index: () => `/dashboard/export-records/`,
    //post
    iot: () => `/dashboard/export-iot-data/`,

    //patch
    cancel: (exportId) => `/dashboard/export-records/${exportId}/`,
    //get
    details: (exportId) => `/dashboard/export-records/${exportId}/`,
  },
  aiSync: {
    //post
    index: () => `/dashboard/export-and-upload-records/`,
  },
}
