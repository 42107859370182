export const actionIcons = {
  'rider-profile-fetch': require('@/assets/icon/timeline/rider-profile-fetch.svg?inline'),
  'admin-force-lock-request': require('@/assets/icon/timeline/force-lock-request.svg?inline'),
  'admin-force-locked': require('@/assets/icon/timeline/force-locked.svg?inline'),
  'admin-force-trip-completed': require('@/assets/icon/timeline/admin-force-trip-completed.svg?inline'),
  'admin-force-unlock-request': require('@/assets/icon/timeline/force-unlock-request.svg?inline'),
  'admin-force-unlocked': require('@/assets/icon/timeline/force-unlocked.svg?inline'),
  'bad-request-400': require('@/assets/icon/timeline/bad-request-400.svg?inline'),
  'bad-request-402': require('@/assets/icon/timeline/bad-request-402.svg?inline'),
  'bad-request-403': require('@/assets/icon/timeline/bad-request-403.svg?inline'),
  'bad-request-404': require('@/assets/icon/timeline/bad-request-404.svg?inline'),
  'bad-request-406': require('@/assets/icon/timeline/bad-request-406.svg?inline'),
  'bad-request-500': require('@/assets/icon/timeline/bad-request-500.svg?inline'),
  'bike-speed-mode-changed': require('@/assets/icon/timeline/bike-speed-mode-changed.svg?inline'),
  'bike-tailight-off': require('@/assets/icon/timeline/bike-tailight-off.svg?inline'),
  'bike-tailight-on': require('@/assets/icon/timeline/bike-tailight-on.svg?inline'),
  'card-switch': require('@/assets/icon/timeline/card-switch.svg?inline'),
  'money-transfer': require('@/assets/icon/timeline/money-transfer.svg?inline'),
  'negative-balance-adjustment': require('@/assets/icon/timeline/negative-balance-adjustment.svg?inline'),
  'operator-spot-reservation-Cacnelled': require('@/assets/icon/timeline/operator-spot-reservation-Cacnelled.svg?inline'),
  'operator-spot-reserved': require('@/assets/icon/timeline/operator-spot-reserved.svg?inline'),
  'operator-task-assigned': require('@/assets/icon/timeline/operator-task-assigned.svg?inline'),
  'operator-task-bulk-completed': require('@/assets/icon/timeline/operator-task-bulk-completed.svg?inline'),
  'operator-task-cancelled': require('@/assets/icon/timeline/operator-task-cancelled.svg?inline'),
  'operator-task-changed': require('@/assets/icon/timeline/operator-task-changed.svg?inline'),
  'operator-task-completed': require('@/assets/icon/timeline/operator-task-completed.svg?inline'),
  'operator-task-created': require('@/assets/icon/timeline/operator-task-created.svg?inline'),
  'operator-task-deleted': require('@/assets/icon/timeline/operator-task-deleted.svg?inline'),
  'operator-vehicle-picked': require('@/assets/icon/timeline/operator-vehicle-picked.svg?inline'),
  'payment-method-switch': require('@/assets/icon/timeline/payment-method-switch.svg?inline'),
  'positive-balance-adjustment': require('@/assets/icon/timeline/positive-balance-adjustment.svg?inline'),
  'reservation-cancelled': require('@/assets/icon/timeline/reservation-cancelled.svg?inline'),
  'restricted-area-alert': require('@/assets/icon/timeline/restricted-area-alert.svg?inline'),
  'rider-account-activated': require('@/assets/icon/timeline/rider-account-activated.svg?inline'),
  'rider-account-deactivated': require('@/assets/icon/timeline/rider-account-deactivated.svg?inline'),
  'rider-bad-feedback': require('@/assets/icon/timeline/rider-bad-feedback.svg?inline'),
  'rider-cable-lock-request': require('@/assets/icon/timeline/rider-cable-lock-request.svg?inline'),
  'rider-financial-account-created': require('@/assets/icon/timeline/rider-financial-account-created.svg?inline'),
  'rider-financial-account-updated': require('@/assets/icon/timeline/rider-financial-account-updated.svg?inline'),
  'rider-good-feedback': require('@/assets/icon/timeline/rider-good-feedback.svg?inline'),
  'rider-illegal-parking': require('@/assets/icon/timeline/rider-illegal-parking.svg?inline'),
  'rider-legal-parking': require('@/assets/icon/timeline/rider-legal-parking.svg?inline'),
  'rider-lock-request': require('@/assets/icon/timeline/rider-lock-request.svg?inline'),
  'rider-logged-in': require('@/assets/icon/timeline/rider-logged-in.svg?inline'),
  'rider-logged-out': require('@/assets/icon/timeline/rider-logged-out.svg?inline'),
  'rider-on-trip': require('@/assets/icon/timeline/rider-on-trip.svg?inline'),
  'rider-parking-photo': require('@/assets/icon/timeline/rider-parking-photo.svg?inline'),
  'rider-voucher-applied': require('@/assets/icon/timeline/voucher-applied.svg?inline'),
  'rider-pass-purchased': require('@/assets/icon/timeline/rider-pass-purchased.svg?inline'),
  'rider-payment-failed': require('@/assets/icon/timeline/rider-payment-failed.svg?inline'),
  'rider-payment-method-add': require('@/assets/icon/timeline/rider-payment-method-add.svg?inline'),
  'rider-payment-method-edited': require('@/assets/icon/timeline/rider-payment-method-edited.svg?inline'),
  'rider-payment-method-expired': require('@/assets/icon/timeline/rider-payment-method-expired.svg?inline'),
  'rider-payment-success': require('@/assets/icon/timeline/rider-payment-success.svg?inline'),
  'rider-personal-id-added': require('@/assets/icon/timeline/rider-personal-id-added.svg?inline'),
  'rider-point-added': require('@/assets/icon/timeline/rider-point-added.svg?inline'),
  'rider-point-deducted': require('@/assets/icon/timeline/rider-point-deducted.svg?inline'),
  'rider-profile': require('@/assets/icon/timeline/rider-profile.svg?inline'),
  'rider-promo-applied': require('@/assets/icon/timeline/rider-promo-applied.svg?inline'),
  'rider-refund-failure': require('@/assets/icon/timeline/rider-refund-failure.svg?inline'),
  'rider-refund-success': require('@/assets/icon/timeline/rider-refund-success.svg?inline'),
  'rider-refunded': require('@/assets/icon/timeline/rider-refunded.svg?inline'),
  'rider-report': require('@/assets/icon/timeline/rider-report.svg?inline'),
  'rider-sign-up': require('@/assets/icon/timeline/rider-sign-up.svg?inline'),
  'rider-trip-compeleted': require('@/assets/icon/timeline/rider-trip-compeleted.svg?inline'),
  'rider-wallet-top-up': require('@/assets/icon/timeline/rider-wallet-top-up.svg?inline'),
  'slow-speed-zone-alert': require('@/assets/icon/timeline/slow-speed-zone-alert.svg?inline'),
  'start-ride': require('@/assets/icon/timeline/start-ride.svg?inline'),
  'vehicle-cable-locked': require('@/assets/icon/timeline/vehicle-cable-locked.svg?inline'),
  'vehicle-cable-unlocked': require('@/assets/icon/timeline/vehicle-cable-unlocked.svg?inline'),
  'vehicle-cable-unlocking': require('@/assets/icon/timeline/vehicle-cable-unlocking.svg?inline'),
  'vehicle-headlight-off': require('@/assets/icon/timeline/vehicle-headlight-off.svg?inline'),
  'vehicle-headlight-on': require('@/assets/icon/timeline/vehicle-headlight-on.svg?inline'),
  'vehicle-illegal-movement-alert': require('@/assets/icon/timeline/vehicle-illegal-movement-alert.svg?inline'),
  'vehicle-inactive': require('@/assets/icon/timeline/vehicle-inactive.svg?inline'),
  'vehicle-lock-status': require('@/assets/icon/timeline/vehicle-lock-status.svg?inline'),
  'vehicle-locked': require('@/assets/icon/timeline/vehicle-locked.svg?inline'),
  'vehicle-out-of-geofence': require('@/assets/icon/timeline/vehicle-out-of-geofence.svg?inline'),
  'vehicle-reserved': require('@/assets/icon/timeline/vehicle-reserved.svg?inline'),
  'vehicle-throttle-off': require('@/assets/icon/timeline/vehicle-throttle-off.svg?inline'),
  'vehicle-throttle-on': require('@/assets/icon/timeline/vehicle-throttle-on.svg?inline'),
  'vehicle-unlockd-battery': require('@/assets/icon/timeline/vehicle-unlockd-battery.svg?inline'),
  'vehicle-unlocked': require('@/assets/icon/timeline/vehicle-unlocked.svg?inline'),
  'vehicle-unlocking': require('@/assets/icon/timeline/vehicle-unlocking.svg?inline'),
  'vehicle-active': require('@/assets/icon/timeline/vehicle-active.svg?inline'),
  'trip-reverted': require('@/assets/icon/timeline/trip-reverted.svg?inline'),
  'nearby-api': require('@/assets/icon/timeline/nearby-api.svg?inline'),
  'toggle-pause': require('@/assets/icon/timeline/toggle-pause.svg?inline'),
  'lock-for-pause': require('@/assets/icon/timeline/lock-for-pause.svg?inline'),
  'unlock-for-unpause': require('@/assets/icon/timeline/unlock-for-unpause.svg?inline'),
  'guest-add': require('@/assets/icon/timeline/guest-add.svg?inline'),
  'guest-fetch': require('@/assets/icon/timeline/guest-fetch.svg?inline'),
  'parking-photo-upload': require('@/assets/icon/timeline/parking-photo-upload.svg?inline'),
  'pause-status': require('@/assets/icon/timeline/pause-status.svg?inline'),
  'reserve-vehicle': require('@/assets/icon/timeline/reserve-vehicle.svg?inline'),
  'lock-for-pause-status': require('@/assets/icon/timeline/lock-for-pause-status.svg?inline'),
  'unlock-for-unpause-status': require('@/assets/icon/timeline/unlock-for-unpause-status.svg?inline'),
  'trip-detail': require('@/assets/icon/timeline/trip-detail.svg?inline'),
  'trip-force-completed': require('@/assets/icon/timeline/trip-force-completed.svg?inline'),
  'vehicle-inside-geofence': require('@/assets/icon/timeline/vehicle-inside-geofence.svg?inline'),
  'policy-penalty': require('@/assets/icon/timeline/policy-penalty.svg?inline'),
  'rent-requested': require('@/assets/icon/timeline/rent-requested.svg?inline'),
  'rent-accepted': require('@/assets/icon/timeline/rent-accepted.svg?inline'),
  'rent-delivered': require('@/assets/icon/timeline/rent-delivered.svg?inline'),
  'rent-completed': require('@/assets/icon/timeline/rent-completed.svg?inline'),
  'rent-rejected': require('@/assets/icon/timeline/rent-rejected.svg?inline'),
  'rent-cancel-request': require('@/assets/icon/timeline/rent-cancel-request.svg?inline'),
  'trigger-payment': require('@/assets/img/flags/trigger_payment_applied.svg?inline'),
  'suspicious-unlock': require('@/assets/icon/timeline/suspicious-unlock.svg?inline'),
  'suspicious-lock': require('@/assets/icon/timeline/suspicious-lock.svg?inline'),
  'critical-battery': require('@/assets/img/flags/on-low-battery.svg?inline'),
  'nearby-vehicles': require('@/assets/icon/timeline/nearby-vehicles.svg?inline'),
  'auto-renewal': require('@/assets/icon/auto-renewal.svg?inline'),
}

export const actionGroups = {
  profileFetched: ['USER_FETCHED_PROFILE'],
  personalIDCardAdded: ['USER_PERSONAL_ID_ADDED'],

  accountActivated: ['USER_ACCOUNT_ACTIVATED'],

  accountDeactivated: ['USER_ACCOUNT_DEACTIVATED', 'USER_REWARDED_POINTS'],

  rewardPointAdded: ['USER_REWARDED_POINTS'],

  signedUp: ['USER_SIGNED_UP'],

  loggedIn: ['USER_LOGGED_IN', 'LOGIN'],

  loggedOut: ['USER_LOGGED_OUT', 'LOGOUT'],

  goodFeedback: ['USER_GOOD_FEEDBACK', 'BIKE_GOOD_FEEDBACK', 'GOOD_FEEDBACK'],

  badFeedback: ['USER_BAD_FEEDBACK', 'BIKE_BAD_FEEDBACK', 'BAD_FEEDBACK'],

  legallyParked: ['BIKE_LEGALLY_PARKED', 'USER_LEGALLY_PARKED_BIKE'],

  illegallyParked: ['BIKE_ILLEGALLY_PARKED', 'USER_LEGALLY_PARKED_BIKE'],

  illegallyMoved: ['BIKE_ILLEGALLY_MOVED', 'ILLEGAL_MOVE'],

  parkingPhotoUploaded: [
    'USER_UPLOADED_PARKING_PHOTO',
    'OPERATOR_RELEASE_PHOTO_UPLOADED',
  ],

  reported: ['USER_REPORTED_BIKE', 'BIKE_GOT_REPORTED', 'REPORT'],

  addedPaymentMethod: ['USER_ADDED_PAYMENT_METHOD', 'CARD_ADD'],

  updatedPaymentMethod: ['USER_UPDATED_PAYMENT_METHOD'],

  creditCardExpired: ['USER_PAYMENT_METHOD_EXPIRED'],

  toppedUpToWallet: ['USER_TOPUP_WALLET', 'USER_PAYMENT_SUCCESS'],

  financialAccountCreated: ['USER_FIN_AC_CREATED'],

  financialAccountUpdated: ['USER_FIN_AC_UPDATED'],

  paymentRefunded: ['USER_TRIP_REFUNDED', 'USER_REFUND_SUCCESS'],

  filledDispute: ['USER_FILED_DISPUTE'],

  promoApplied: ['USER_APPLIED_PROMO', 'PROMO_APPLY'],

  pass: [
    'USER_SUBSCRIBED_PASS',
    'REDIRECT_PASS_SUBSCRIBE',
    'DIRECT_PASS_SUBSCRIBE',
  ],
  voucherApplied: ['VOUCHER_APPLY'],

  vehicleLockRequest: [
    'BIKE_LOCK_REQUEST',
    'BIKE_LOCKING',
    'LOCK',
    'LOCK_RENT_VEHICLE',
  ],

  vehicleLocked: [
    'BIKE_LOCKED',
    'OPERATOR_VEHICLE_LOCKED',
    'LOCKED',
    'LOCKED_FOR_PAUSE',
    'LOCKED_RENT_VEHICLE',
  ],

  cableLockRequest: ['BIKE_CABLE_LOCK_REQUEST'],

  cableLocked: ['BIKE_CABLE_LOCKED'],

  forceLockRequest: ['BIKE_FORCE_LOCK_REQUEST', 'FORCE_LOCK'],
  forceLocked: ['FORCE_LOCKED'],
  forceUnlockRequest: ['BIKE_FORCE_UNLOCK_REQUEST', 'FORCE_UNLOCK'],
  forceUnlocked: ['FORCE_UNLOCKED'],

  vehicleUnlocking: ['BIKE_UNLOCKING', 'UNLOCK'],

  vehicleUnlocked: [
    'BIKE_UNLOCKED',
    'OPERATOR_VEHICLE_UNLOCKED',
    'UNLOCKED',
    'UNLOCKED_FOR_UNPAUSE',
    'UNLOCKED_RENT_VEHICLE',
  ],

  vehicleUnlockRequest: ['BIKE_UNLOCK_REQUEST', 'UNLOCK_RENT_VEHICLE'],

  cableUnlockRequest: ['BIKE_CABLE_UNLOCK_REQUEST'],

  cableUnlocked: ['BIKE_CABLE_UNLOCKED'],

  vehicleReserved: ['BIKE_RESERVED', 'USER_RESERVED_BIKE'],

  reservationCancel: [
    'USER_CANCELLED_RESERVATION',
    'BIKE_RESERVATION_CANCELLED',
    'BIKE_RESERVATION_TIMEOUT',
    'USER_RESERVATION_TIMEOUT',
    'RESERVATION_TIMED_OUT',
    'VEHICLE_RENTAL_CANCELLED',
    'RESERVATION_CANCEL',
  ],

  tripReverted: ['USER_TRIP_REVERTED', 'BIKE_TRIP_REVERTED'],

  tripStart: ['BIKE_TRIP_STARTED', 'USER_ON_TRIP'],

  tripCancel: ['BIKE_TRIP_CANCELLED', 'USER_CANCELLED_TRIP'],

  tripCompleted: [
    'USER_COMPLETED_TRIP',
    'BIKE_TRIP_COMPLETED',
    'COMPLETE_TRIP',
  ],

  tripForceComplete: [
    'USER_FORCE_TRIP_COMPLETED',
    'BIKE_FORCE_TRIP_COMPLETED',
    'ADMIN_RENT_COMPLETE',
  ],

  vehicleActive: ['BIKE_ACTIVE', 'ACTIVE_VEHICLE'],

  vehicleInactive: ['BIKE_INACTIVE', 'IDLE_VEHICLE'],

  chargerConnected: ['BIKE_CHARGER_CONNECTED'],

  chargerDisconnected: ['BIKE_CHARGER_DISCONNECTED'],

  throttleOn: [
    'BIKE_THROTTLE_ON',
    'OPERATOR_VEHICLE_THROTTLE_ON',
    'THROTTLE_ON',
  ],

  throttleOff: [
    'BIKE_THROTTLE_OFF',
    'OPERATOR_VEHICLE_THROTTLE_OFF',
    'THROTTLE_OFF',
  ],

  headLightOn: [
    'BIKE_HEADLIGHT_ON',
    'OPERATOR_VEHICLE_HEADLIGHT_ON',
    'HEADLIGHT_ON',
  ],

  headLightOff: [
    'BIKE_HEADLIGHT_OFF',
    'OPERATOR_VEHICLE_HEADLIGHT_OFF',
    'HEADLIGHT_OFF',
  ],

  tailLightOn: [
    'BIKE_TAILLIGHT_ON',
    'OPERATOR_VEHICLE_TAILLIGHT_ON',
    'TAILLIGHT_ON',
  ],

  tailLightOff: [
    'BIKE_TAILLIGHT_OFF',
    'OPERATOR_VEHICLE_TAILLIGHT_OFF',
    'TAILLIGHT_OFF',
  ],

  speedModeUpdated: ['BIKE_SPEED_MODE_UPDATED'],

  ringedAlarm: ['BIKE_RING_ALARM', 'OPERATOR_VEHICLE_RINGED'],

  checkLockBatteryStatus: ['BIKE_CHECK_LOCK_STATUS'],

  batteryLocked: ['OPERATOR_VEHICLE_BATTERY_LOCKED'],

  batteryUnlocked: [
    'BIKE_UNLOCKED_BATTERY',
    'OPERATOR_VEHICLE_BATTERY_UNLOCKED',
  ],

  taskCreated: ['OPERATOR_TASK_CREATED'],

  taskAssigned: ['OPERATOR_TASK_ASSIGNED'],

  taskInProgress: ['OPERATOR_TASK_IN_PROGRESS'],

  taskCompleted: ['OPERATOR_TASK_IN_PROGRESS'],

  taskBulkInProgress: ['OPERATOR_TASK_BULK_PICKED'],

  taskCancelled: ['OPERATOR_TASK_CANCELLED'],

  taskDeleted: ['OPERATOR_TASK_DELETED'],

  taskBulkCompleted: ['OPERATOR_TASK_BULK_COMPLETED'],

  releaseSpotReserved: ['OPERATOR_RELEASE_SPOT_RESERVED'],

  releaseSpotReservationCancelled: [
    'OPERATOR_RELEASE_SPOT_RESERVATION_CANCELLED',
  ],

  taskEdited: [
    'OPERATOR_TASK_TYPE_CHANGED',
    'OPERATOR_TASK_DUE_DATE_CHANGED',
    'OPERATOR_TASK_PRIORITY_CHANGED',
    'OPERATOR_TASK_VEHICLE_CHANGED',
    'OPERATOR_TASK_TITLE_CHANGED',
    'OPERATOR_TASK_DESCRIPTION_CHANGED',
    'OPERATOR_TASK_TAG_UPDATED',
  ],

  outOfGeofenceAlert: ['BIKE_OUT_OF_GEOFENCE', 'VEHICLE_OUTSIDE_GEOFENCE'],
  restrictedAreaAlert: ['VEHICLE_INSIDE_RESTRICTED_AREA'],
  slowSpeedZoneAlert: ['VEHICLE_INSIDE_SLOWZONE_AREA'],
  nearbyAPI: ['USER_NEARBY_FOUND'],

  fetchedTripDetails: ['USER_FETCHED_TRIP_DETAILS'],

  error: ['USER_TOO_MANY_REQS'],

  togglePause: ['TOGGLE_PAUSE'],

  lockForPause: ['LOCK_FOR_PAUSE'],
  unlockForPause: ['UNLOCK_FOR_UNPAUSE'],
  guestAdd: ['GUEST_ADD'],
  guestFetch: ['GUEST_FETCH'],
  parkingPhotoUpload: ['PARKING_PHOTO_UPLOAD'],
  pauseStatus: ['PAUSE_STATUS'],
  reserveVehicle: ['RESERVE_VEHICLE'],
  lockForPauseStatus: ['LOCK_STATUS_FOR_PAUSE', 'LOCK_STATUS_FOR_LOCK'],
  unlockForUnpauseStatus: [
    'UNLOCK_STATUS_FOR_UNPAUSE',
    'UNLOCK_STATUS_FOR_UNLOCK',
  ],
  tripDetail: ['TRIP_DETAIL'],
  riderProfile: ['USER_PROFILE', 'RIDER_PROFILE'],
  tripForceCompleted: ['FORCE_COMPLETE_TRIP'],
  triggerPayment: ['ADMIN_TRIGGER_PAYMENT', 'TRIGGER_PAYMENT_BY_SYSTEM'],
  vehicleInsideGeofence: [
    'VEHICLE_INSIDE_GEOFENCE',
    'VEHICLE_OUTSIDE_RESTRICTED_AREA',
  ],
  policyPenalty: ['PENALIZED_FOR_POLICY_VIOLATION'],
  rentRequested: ['VEHICLE_RENTAL_REQUESTED', 'REQUEST_FOR_RENT'],
  rentAccepted: ['VEHICLE_RENTAL_ACCEPTED', 'RENT_ACCEPT'],
  rentDelivered: ['VEHICLE_RENTAL_DELIVERED'],
  rentCompleted: ['VEHICLE_RENTAL_COMPLETED', 'RENT_COMPLETE'],
  rentRejected: ['VEHICLE_RENTAL_REJECTED'],
  rentCancelRequest: ['VEHICLE_RENTAL_CANCELLATION_REQUESTED'],
  suspiciousUnlock: ['SUSPICIOUS_UNLOCK'],
  suspiciousLock: ['SUSPICIOUS_LOCK'],
  criticalBattery: ['CRITICAL_BATTERY'],
  nearbyVehicles: ['NEARBY_VEHICLES'],
  autoRenewal: ['RENTAL_AUTO_RENEWAL'],
}

const i = actionIcons
export const actionGroupIcons = {
  profileFetched: i['rider-profile-fetch'],
  personalIDCardAdded: i['rider-personal-id-added'],
  accountActivated: i['rider-account-activated'],
  accountDeactivated: i['rider-account-deactivated'],
  rewardPointAdded: i['rider-point-added'],
  signedUp: i['rider-sign-up'],
  loggedIn: i['rider-logged-in'],
  loggedOut: i['rider-logged-out'],
  goodFeedback: i['rider-good-feedback'],
  badFeedback: i['rider-bad-feedback'],
  legallyParked: i['rider-legal-parking'],
  illegallyParked: i['rider-illegal-parking'],
  illegallyMoved: i['vehicle-illegal-movement-alert'],
  parkingPhotoUploaded: i['rider-parking-photo'],
  reported: i['rider-report'],
  addedPaymentMethod: i['rider-payment-method-add'],
  updatedPaymentMethod: i['rider-payment-method-edited'],
  creditCardExpired: i['rider-payment-method-expired'],
  toppedUpToWallet: i['rider-wallet-top-up'],
  financialAccountCreated: i['rider-financial-account-created'],
  financialAccountUpdated: i['rider-financial-account-updated'],
  paymentRefunded: i['rider-refunded'],
  filledDispute: i['rider-report'],
  promoApplied: i['rider-promo-applied'],
  pass: i['rider-pass-purchased'],
  voucherApplied: i['rider-voucher-applied'],
  vehicleLockRequest: i['rider-lock-request'],
  vehicleLocked: i['vehicle-locked'],
  cableLockRequest: i['rider-cable-lock-request'],
  cableLocked: i['vehicle-cable-locked'],
  forceLockRequest: i['admin-force-lock-request'],
  forceLocked: i['admin-force-locked'],
  forceUnlockRequest: i['admin-force-unlock-request'],
  forceUnlocked: i['admin-force-unlocked'],
  vehicleUnlocking: i['vehicle-unlocking'],
  vehicleUnlocked: i['vehicle-unlocked'],
  vehicleUnlockRequest: i['vehicle-unlocking'],
  cableUnlockRequest: i['vehicle-cable-unlocking'],
  cableUnlocked: i['vehicle-cable-unlocked'],
  vehicleReserved: i['vehicle-reserved'],
  reservationCancel: i['reservation-cancelled'],
  tripReverted: i['trip-reverted'],
  tripStart: i['start-ride'],
  tripCancel: i['reservation-cancelled'],
  tripCompleted: i['rider-trip-compeleted'],
  tripForceComplete: i['admin-force-trip-completed'],
  vehicleActive: i['vehicle-active'],
  vehicleInactive: i['vehicle-inactive'],
  chargerConnected: i['vehicle-cable-locked'],
  chargerDisconnected: i['vehicle-cable-unlocked'],
  throttleOn: i['vehicle-throttle-on'],
  throttleOff: i['vehicle-throttle-off'],
  headLightOn: i['vehicle-headlight-on'],
  headLightOff: i['vehicle-headlight-off'],
  tailLightOn: i['bike-tailight-on'],
  tailLightOff: i['bike-tailight-off'],
  ringedAlarm: i['bike-tailight-on'],
  checkLockBatteryStatus: i['vehicle-lock-status'],
  batteryLocked: i['vehicle-locked'],
  taskCreated: i['operator-task-created'],
  taskAssigned: i['operator-task-assigned'],
  taskInProgress: i['operator-vehicle-picked'],
  taskBulkInProgress: i['operator-vehicle-picked'],
  taskCompleted: i['operator-task-completed'],
  taskCancelled: i['operator-task-cancelled'],
  taskDeleted: i['operator-task-deleted'],
  taskBulkCompleted: i['operator-task-bulk-completed'],
  releaseSpotReserved: i['operator-spot-reserved'],
  releaseSpotReservationCancelled: i['operator-spot-reservation-Cacnelled'],
  taskEdited: i['operator-task-changed'],
  outOfGeofenceAlert: i['vehicle-out-of-geofence'],
  restrictedAreaAlert: i['restricted-area-alert'],
  slowSpeedZoneAlert: i['slow-speed-zone-alert'],
  nearbyAPI: i['nearby-api'],
  fetchedTripDetails: i['nearby-api'],
  error: i['bad-request-400'],
  togglePause: i['toggle-pause'],
  lockForPause: i['lock-for-pause'],
  unlockForPause: i['unlock-for-unpause'],
  guestAdd: i['guest-add'],
  guestFetch: i['guest-fetch'],
  parkingPhotoUpload: i['parking-photo-upload'],
  pauseStatus: i['pause-status'],
  reserveVehicle: i['reserve-vehicle'],
  lockForPauseStatus: i['lock-for-pause-status'],
  unlockForUnpauseStatus: i['unlock-for-unpause-status'],
  tripDetail: i['trip-detail'],
  triggerPayment: i['trigger-payment'],
  riderProfile: i['rider-profile'],
  tripForceCompleted: i['trip-force-completed'],
  vehicleInsideGeofence: i['vehicle-inside-geofence'],
  policyPenalty: i['policy-penalty'],
  rentRequested: i['rent-requested'],
  rentAccepted: i['rent-accepted'],
  rentDelivered: i['rent-delivered'],
  rentCompleted: i['rent-completed'],
  rentRejected: i['rent-rejected'],
  rentCancelRequest: i['rent-cancel-request'],
  suspiciousUnlock: i['suspicious-unlock'],
  suspiciousLock: i['suspicious-lock'],
  criticalBattery: i['critical-battery'],
  nearbyVehicles: i['nearby-vehicles'],
  autoRenewal: i['auto-renewal'],
}

export const allActions = () => {
  const actions = []
  for (const key in actionGroups) {
    actions.push(...actionGroups[key])
  }
  return actions
}

export const allGroups = () => Object.keys(actionGroups)

export const findActionFromGroup = (action, group) => {
  const actions = actionGroups[group]
  return actions.find((a) => a === action)
}

export const findGroupFromAction = (action) => {
  const groups = allGroups()
  const group = groups.find((g) => findActionFromGroup(action, g))
  return group
}

// console.log(findGroupFromAction('USER_FETCHED_PROFILE'))

// use this function to get an icon for an action
export const getIconByAction = (action) => {
  const group = findGroupFromAction(action)
  return actionGroupIcons[group] || group
}
