import Vue from 'vue'
import VueRouter from 'vue-router'
// Middlewares
import { ifAuthenticated } from '@/middlewares/ifAuthenticated'
import { ifNotAuthenticated } from '@/middlewares/ifNotAuthenticated'
import { ifSuperAdmin } from '@/middlewares/ifSuperAdmin'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(
        /* webpackChunkName: "dashboard-dashboard" */ '@/views/dashboard/Dashboard.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  /* ----------------
      Country Management
  ----------------- */
  {
    path: '/currencies',
    name: 'Currencies',
    component: () =>
      import(
        /* webpackChunkName: "dashboard-dashboard" */ '@/views/country-management/currencies/Currencies.vue'
      ),
    beforeEnter: ifSuperAdmin,
  },
  {
    path: '/countries',
    name: 'Countries',
    component: () =>
      import(
        /* webpackChunkName: "dashboard-dashboard" */ '@/views/country-management/countries/Countries.vue'
      ),
    beforeEnter: ifSuperAdmin,
  },
  {
    path: '/cities',
    name: 'Cities',
    component: () =>
      import(
        /* webpackChunkName: "dashboard-dashboard" */ '@/views/country-management/cities/Cities.vue'
      ),
    beforeEnter: ifSuperAdmin,
  },
  /* ----------------
      Heatmap
  ----------------- */
  {
    path: '/predictions',
    name: 'DemandHeatmap',
    component: () =>
      import(
        /* webpackChunkName: "dashboard-demand-heatmap" */ '@/views/dashboard/DemandHeatmapV2/HybridHeatMap.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/hardware',
    name: 'HardwareAnalytics',
    component: () =>
      import(
        /* webpackChunkName: "dashboard-hardware-analytics" */ '@/views/dashboard/HardwareAnalytics.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  /* ----------------
      Login
  ----------------- */
  {
    //--dynamically switch login component code here depend on website
  },
  /* ----------------
      Auth
  ----------------- */
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */
        '@/views/auth/ResetPassword.vue'
      ),
  },
  {
    path: '/activate-user',
    name: 'Activate User',
    component: () =>
      import(
        /* webpackChunkName: "activate-user" */
        '@/views/auth/ActivateUser.vue'
      ),
    // beforeEnter: ifNotAuthenticated
  },
  {
    path: '/update-password',
    name: 'UpdateUserPassword',
    component: () =>
      import(
        /* webpackChunkName: "update-password" */
        '@/views/auth/UpdateUserPass.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  /* ----------------
      HelpCenter
  ----------------- */
  {
    path: '/help/getting-started',
    name: 'HelpCenterGettingStarted',
    component: () => import('@/views/help-center/GettingStarted.vue'),
    beforeEnter: ifAuthenticated,
  },

  /* ----------------
      GeoFence
  ----------------- */
  {
    path: '/geofence/service-areas',
    name: 'Service Areas',
    component: () =>
      import(
        /* webpackChunkName: "service-area" */
        '@/views/geofence/service-area/ServiceAreas.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/geofence/service-areas/:id',
    name: 'ViewServiceArea',
    component: () =>
      import(
        /* webpackChunkName: "service-area" */
        '@/views/geofence/service-area/ViewServiceArea.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/geofence/parking-areas',
    name: 'ParkingAreas',
    component: () =>
      import(
        /* webpackChunkName: "parking-area" */
        '@/views/geofence/parking-area/ParkingAreas.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/geofence/parking-areas/:id',
    name: 'ViewParkingArea',
    component: () =>
      import(
        /* webpackChunkName: "parking-area" */
        '@/views/geofence/parking-area/ViewParkingArea.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/geofence/restricted-areas',
    name: 'RestrictedAreas',
    component: () =>
      import(
        /* webpackChunkName: "restricted-area" */
        '@/views/geofence/restricted-area/RestrictedAreas.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/geofence/restricted-areas/:id',
    name: 'ViewRestrictedArea',
    component: () =>
      import(
        /* webpackChunkName: "restricted-area" */
        '@/views/geofence/restricted-area/ViewRestrictedArea.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/geofence/slow-zone-areas',
    name: 'SlowSpeedAreas',
    component: () =>
      import(
        /* webpackChunkName: "login" */
        '@/views/geofence/slow-zone-area/SlowZoneAreas.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/geofence/slow-zone-areas/:id',
    name: 'ViewSlowZoneArea',
    component: () =>
      import(
        /* webpackChunkName: "restricted-area" */
        '@/views/geofence/slow-zone-area/ViewSlowZoneArea.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/geofence/rent-station-areas',
    name: 'RentStationIndex',
    component: () =>
      import(
        /* webpackChunkName: "login" */
        '@/views/geofence/rent-station-area/RentStationAreasIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/geofence/rent-station-areas/:id',
    name: 'ViewRenStationArea',
    component: () =>
      import(
        /* webpackChunkName: "login" */
        '@/views/geofence/rent-station-area/ViewRenStationArea.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/geofence/sunpod-station-areas',
    name: 'SunpodStationIndex',
    component: () =>
      import(
        /* webpackChunkName: "login" */
        '@/views/geofence/sunpod-station-area/SunpodStationIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/geofence/sunpod-station-areas/:id',
    name: 'ViewSunpodStationArea',
    component: () =>
      import(
        /* webpackChunkName: "login" */
        '@/views/geofence/sunpod-station-area/ViewSunpodStationArea.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/geofence/reward-zones',
    name: 'RewardZones',
    component: () =>
      import(
        /* webpackChunkName: "parking-area" */
        '@/views/geofence/reward-zone/RewardZones.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/geofence/reward-zones/:id',
    name: 'ViewRewardZone',
    component: () =>
      import(
        /* webpackChunkName: "parking-area" */
        '@/views/geofence/reward-zone/ViewRewardZone.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  /* ----------------
      Logs
  ----------------- */
  {
    path: '/logs/sign-in/',
    name: 'ViewSMSLogs',
    component: () =>
      import(
        /* webpackChunkName: "restricted-area" */
        '@/views/Logs/SmsLogs.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  /* ----------------
      Subscription
  ----------------- */
  {
    path: '/pricing/subscription-plans',
    name: 'Subscription Plans',
    component: () =>
      import(
        /* webpackChunkName: "pricing-subscription-plans" */
        '@/views/pricing/SubscriptionPlanIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/pricing/tax-plans',
    name: 'ViewTaxPlan',
    component: () =>
      import(
        /* webpackChunkName: "tax-plans" */ '@/views/pricing/TaxPlanIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/pricing/billing-plans',
    name: 'Billing Management',
    component: () =>
      import(
        /* webpackChunkName: "billing-plans" */
        '@/views/pricing/BillingPlanIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/pricing/private-billing-plans',
    name: 'PrivateBillingPlanIndex',
    component: () =>
      import(
        /* webpackChunkName: "private-billing-plans" */
        '@/views/pricing/PrivateBillingPlanIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/pricing/rent-plans',
    name: 'RentPlans',
    component: () =>
      import(
        /* webpackChunkName: "rent-plans" */
        '@/views/pricing/RentPlanIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  /* ----------------
      Sunpods
  ----------------- */
  {
    path: '/sunpods',
    name: 'SunpodIndex',
    component: () =>
      import(/* webpackChunkName: "iot" */ '@/views/sunpods/SunpodIndex.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/sunpods/:id',
    name: 'ViewSunpodDetails',
    component: () => import('@/views/sunpods/ViewSunpodDetails.vue'),
    beforeEnter: ifAuthenticated,
    children: [
      {
        name: 'ViewSunpodProfile',
        path: 'profile',
        component: () =>
          import('@/views/sunpods/details-tabs/ViewSunpodProfile.vue'),
      },
      {
        name: 'ViewSunpodVehicles',
        path: 'vehicles',
        component: () =>
          import('@/views/sunpods/details-tabs/ViewSunpodVehicles.vue'),
      },
      {
        name: 'ViewSunpodLogs',
        path: 'logs',
        component: () =>
          import('@/views/sunpods/details-tabs/ViewSunpodLogs.vue'),
      },
      {
        name: 'ViewSunpodAlarmLogs',
        path: 'alarm-logs',
        component: () =>
          import('@/views/sunpods/details-tabs/ViewSunpodAlarmLogs.vue'),
      },
      {
        name: 'ViewSunpodCamera',
        path: 'camera',
        component: () =>
          import('@/views/sunpods/details-tabs/ViewSunpodCamera.vue'),
      },
    ],
  },
  {
    path: '/edgebox',
    name: 'EdgeboxIndex',
    component: () =>
      import(/* webpackChunkName: "iot" */ '@/views/sunpods/EdgeboxIndex.vue'),
    beforeEnter: ifAuthenticated,
  },

  /* ----------------
      Vehicle
  ----------------- */
  {
    path: '/vehicle-management/iot',
    name: 'IOT Management',
    component: () =>
      import(/* webpackChunkName: "iot" */ '@/views/vehicle/iot/Locks.vue'),
    beforeEnter: ifSuperAdmin,
  },
  {
    path: '/vehicle-management/vehicle-manufacturers',
    name: 'VehicleManufacturersIndex',
    component: () =>
      import(
        /* webpackChunkName: "VehicleManufacturersIndex" */ '@/views/vehicle-manufacturers/VehicleManufacturersIndex.vue'
      ),
    beforeEnter: ifSuperAdmin,
  },
  {
    path: '/vehicle-management/vehicle-manufacturers/:id',
    name: 'VehicleManufacturersDetails',
    component: () =>
      import(
        /* webpackChunkName: "VehicleManufacturersIndex" */ '@/views/vehicle-manufacturers/VehicleManufacturersDetails.vue'
      ),
    beforeEnter: ifSuperAdmin,
  },
  {
    path: '/vehicle-management/vehicles',
    name: 'VehicleManagement',
    component: () =>
      import(
        /* webpackChunkName: "vehicleIndex" */
        '@/views/vehicle/Vehicles.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/vehicle-management/bikes',
    name: 'BikeIndex',
    component: () =>
      import(/* webpackChunkName: "bikeIndex" */ '@/views/scooter/Bikes.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/vehicle-management/e-bikes',
    name: 'EBikeIndex',
    component: () =>
      import(
        /* webpackChunkName: "eBikesIndex" */ '@/views/scooter/EBikes.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/vehicle-management/scooters-pro',
    name: 'ScooterProIndex',
    component: () =>
      import(
        /* webpackChunkName: "scooterIndex" */ '@/views/scooter/ScootersPro.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/vehicle-management/scooters',
    name: 'ScooterIndex',
    component: () =>
      import(
        /* webpackChunkName: "scooterIndex" */ '@/views/scooter/Scooters.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/vehicle-management/super-cocos',
    name: 'SuperCocoIndex',
    component: () =>
      import(
        /* webpackChunkName: "scooterIndex" */ '@/views/scooter/SuperCocos.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/vehicle-management/kenotas',
    name: 'KenotaIndex',
    component: () =>
      import(
        /* webpackChunkName: "scooterIndex" */ '@/views/scooter/Kenotas.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/vehicle-management/mopeds',
    name: 'MopedIndex',
    component: () =>
      import(
        /* webpackChunkName: "scooterIndex" */ '@/views/scooter/Mopeds.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/vehicle-management/vehicle-models',
    name: 'OrgVehicleModelIndex',
    component: () =>
      import(
        /* webpackChunkName: "scooterIndex" */ '@/views/vehicle-manufacturers/OrgVehicleModelIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/vehicle-management/operation-primer',
    name: 'OperationPrimerIndex',
    component: () =>
      import(
        /* webpackChunkName: "scooterIndex" */ '@/views/operation-primer/OperationPrimerIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/vehicle-management/charging-dock',
    name: 'ChargingDockIndex',
    component: () =>
      import(
        /* webpackChunkName: "scooterIndex" */ '@/views/charging-dock/ChargingDockIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/vehicle-management/vehicles/:id',
    name: 'ViewVehicle',
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "vehicle-management-ViewVehicle" */
        '@/views/vehicle/ViewVehicle.vue'
      ),
    children: [
      {
        name: 'ViewVehicleProfile',
        path: 'profile',
        component: () =>
          import(
            /* webpackChunkName: "vehicle-management-ViewVehicleProfile" */
            '@/views/vehicle/ViewVehicleProfile.vue'
          ),
      },
      {
        name: 'ViewVehicleConfiguration',
        path: 'hardware',
        component: () =>
          import(
            /* webpackChunkName: "vehicle-management-ViewVehicleConfiguration" */
            '@/views/vehicle/ViewVehicleConfiguration.vue'
          ),
      },
      {
        name: 'ViewVehicleLogs',
        path: 'logs',
        component: () =>
          import(
            /* webpackChunkName: "vehicle-management-ViewVehicleLogs" */
            '@/views/vehicle/ViewVehicleLogs.vue'
          ),
      },
      {
        name: 'ViewVehicleRides',
        path: 'rides',
        component: () =>
          import(
            /* webpackChunkName: "vehicle-management-ViewVehicleRides" */
            '@/views/vehicle/ViewVehicleRides.vue'
          ),
      },
      {
        name: 'ViewVehicleRevenues',
        path: 'revenues',
        component: () =>
          import(
            /* webpackChunkName: "vehicle-management-ViewVehicleRevenues" */
            '@/views/vehicle/ViewVehicleRevenues.vue'
          ),
      },
      {
        name: 'ViewVehicleTLine',
        path: 'timeline',
        component: () =>
          import(
            /* webpackChunkName: "vehicle-management-ViewVehicleTLine" */
            '@/views/vehicle/ViewVehicleTLine.vue'
          ),
      },
      {
        name: 'ViewVehicleIotLogs',
        path: 'iot-logs',
        component: () =>
          import(
            /* webpackChunkName: "vehicle-management-ViewVehicleIotLogs" */
            '@/views/vehicle/ViewVehicleIotLogs.vue'
          ),
      },
      {
        name: 'ViewVehicleBattery',
        path: 'battery',
        component: () =>
          import(
            /* webpackChunkName: "vehicle-management-ViewVehicleBattery" */
            '@/views/vehicle/ViewVehicleBattery.vue'
          ),
      },
      {
        name: 'ViewVehicleFlag',
        path: 'flag',
        component: () =>
          import(
            /* webpackChunkName: "vehicle-management-ViewVehicleFlag" */
            '@/views/vehicle/ViewVehicleFlag.vue'
          ),
      },
      {
        name: 'ViewVehicleRepairLogs',
        path: 'repair-logs',
        component: () =>
          import(
            /* webpackChunkName: "vehicle-management-ViewVehicleTasks" */
            '@/views/vehicle/ViewVehicleRepairLogs.vue'
          ),
      },
      {
        name: 'ViewVehicleTasks',
        path: 'tasks',
        component: () =>
          import(
            /* webpackChunkName: "vehicle-management-ViewVehicleTasks" */
            '@/views/vehicle/ViewVehicleTasks.vue'
          ),
      },
      {
        name: 'ViewVehicleReports',
        path: 'reports',
        component: () =>
          import(
            /* webpackChunkName: "vehicle-management-ViewVehicleReports" */
            '@/views/vehicle/ViewVehicleReports.vue'
          ),
      },
      {
        name: 'ViewVehicleHealth',
        path: 'health',
        component: () =>
          import(
            /* webpackChunkName: "vehicle-management-ViewVehicleHealth" */
            '@/views/vehicle/ViewVehicleHealth.vue'
          ),
      },
      {
        name: 'VehicleNoteIndex',
        path: 'notes',
        component: () =>
          import(
            /* webpackChunkName: "vehicle-management-ViewVehicleNotes" */
            '@/views/vehicle/ViewVehicleNotes.vue'
          ),
      },
    ],
  },

  /* ----------------
      User
  ----------------- */
  {
    path: '/users',
    name: 'UserIndex',
    component: () =>
      import(
        /* webpackChunkName: "users-OrganizationUsers" */
        '@/views/user/organization/UserIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/users/franchises',
    name: 'FranchiseIndex',
    component: () =>
      import(
        /* webpackChunkName: "franchises" */
        '@/views/user/organization/UserFranchiseIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/users/operators',
    name: 'OperatorIndex',
    component: () =>
      import(
        /* webpackChunkName: "operators" */
        '@/views/user/organization/UserOperatorIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/users/agents/',
    name: 'AgentIndex',
    component: () =>
      import(
        /* webpackChunkName: "agents" */
        '@/views/user/organization/UserAgentIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/users/p2p-owners/',
    name: 'UserP2POwnerIndex',
    component: () =>
      import(
        /* webpackChunkName: "p2p-owners" */
        '@/views/user/organization/UserP2POwnerIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/users/rent-station-owners/',
    name: 'UserRentStationOwnerIndex',
    component: () =>
      import(
        /* webpackChunkName: "rent-station-owners" */
        '@/views/user/organization/UserRentStationOwnerIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },

  /* ----------------
      User Details
      Todo: rename route names
  ----------------- */
  {
    path: '/users/:id',
    name: 'ViewOrganizationUser',
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "users-ViewOrganizationUser" */
        '@/views/user/organization/UserDetails.vue'
      ),
    children: [
      {
        name: 'ViewOrganizationUserSummary',
        path: 'summary',
        component: () =>
          import(
            /* webpackChunkName: "users-ViewOrganizationUserSummary" */
            '@/views/user/organization/details-tabs/ViewOrganizationUserSummary.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserProfile',
        path: 'profile',
        component: () =>
          import(
            /* webpackChunkName: "users-ViewOrganizationUserProfile" */
            '@/views/user/organization/details-tabs/ViewOrganizationUserProfile.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserFleets',
        path: 'fleets',
        component: () =>
          import(
            /* webpackChunkName: "users-ViewOrganizationUserFleets" */
            '@/views/user/organization/details-tabs/ViewOrganizationUserFleets.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserTopup',
        path: 'topup',
        component: () =>
          import(
            '@/views/user/organization/details-tabs/ViewOrganizationUserTopup.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserVehicles',
        path: 'vehicles',
        component: () =>
          import(
            /* webpackChunkName: "users-ViewOrganizationUserVehicles" */
            '@/views/user/organization/details-tabs/ViewOrganizationUserVehicles.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserPayouts',
        path: 'payouts',
        component: () =>
          import(
            /* webpackChunkName: "users-ViewOrganizationUserPayouts" */
            '@/views/user/organization/details-tabs/ViewOrganizationUserPayouts.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserOperationalAreas',
        path: 'operational-areas',
        component: () =>
          import(
            /* webpackChunkName: "users-ViewOrganizationOperationalAreas" */
            '@/views/user/organization/details-tabs/ViewOrganizationUserOperationalAreas.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserTasks',
        path: 'tasks',
        component: () =>
          import(
            /* webpackChunkName: "users-ViewOrganizationUserTasks" */
            '@/views/user/organization/details-tabs/ViewOrganizationUserTasks.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserLogs',
        path: 'logs',
        component: () =>
          import(
            /* webpackChunkName: "users-ViewOrganizationUserLogs" */
            '@/views/user/organization/details-tabs/ViewOrganizationUserLogs.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserUsers',
        path: 'users',
        component: () =>
          import(
            /* webpackChunkName: "users-ViewOrganizationUserUsers" */
            '@/views/user/organization/details-tabs/ViewOrganizationUserUsers.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserRevenues',
        path: 'revenues',
        component: () =>
          import(
            /* webpackChunkName: "users-ViewOrganizationUserRevenues" */
            '@/views/user/organization/details-tabs/ViewOrganizationUserRevenues.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserRiders',
        path: 'riders',
        component: () =>
          import(
            /* webpackChunkName: "users-ViewOrganizationUserRiders" */
            '@/views/user/organization/details-tabs/ViewOrganizationUserRiders.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserRents',
        path: 'long-rents',
        component: () =>
          import(
            /* webpackChunkName: "users-ViewOrganizationUserRiders" */
            '@/views/user/organization/details-tabs/ViewOrganizationUserRents.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserRides',
        path: 'rents',
        component: () =>
          import(
            /* webpackChunkName: "users-ViewOrganizationUserRiders" */
            '@/views/user/organization/details-tabs/ViewOrganizationUserRides.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserIncoming',
        path: 'incoming',
        component: () =>
          import(
            '@/views/user/organization/details-tabs/ViewOrganizationUserIncoming.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserTrips',
        path: 'trips',
        component: () =>
          import(
            '@/views/user/organization/details-tabs/ViewOrganizationUserTrips.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserPayments',
        path: 'payments',
        component: () =>
          import(
            '@/views/user/organization/details-tabs/ViewOrganizationUserPayments.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserDebit',
        path: 'debit',
        component: () =>
          import(
            '@/views/user/organization/details-tabs/ViewOrganizationUserDebit.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserCredit',
        path: 'credit',
        component: () =>
          import(
            '@/views/user/organization/details-tabs/ViewOrganizationUserCredit.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserReports',
        path: 'reports',
        component: () =>
          import(
            '@/views/user/organization/details-tabs/ViewOrganizationUserReports.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserRentStations',
        path: 'rent-stations',
        component: () =>
          import(
            '@/views/user/organization/details-tabs/ViewOrganizationUserRentStations.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserP2POwnerVehicles',
        path: 'p2p-owner-vehicles',
        component: () =>
          import(
            '@/views/user/organization/details-tabs/ViewOrganizationUserP2POwnerVehicles.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserRentStationsVehicles',
        path: 'rent-stations-vehicles',
        component: () =>
          import(
            '@/views/user/organization/details-tabs/ViewOrganizationUserRentStationsVehicles.vue'
          ),
      },

      {
        name: 'ViewOrganizationUserRentStationsRiders',
        path: 'rent-stations-riders',
        component: () =>
          import(
            '@/views/user/organization/details-tabs/ViewOrganizationUserRentStationsRiders.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserRentStationsRents',
        path: 'rent-stations-rents',
        component: () =>
          import(
            '@/views/user/organization/details-tabs/ViewOrganizationUserRentStationsRents.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserRentStationsRevenues',
        path: 'rent-stations-revenues',
        component: () =>
          import(
            '@/views/user/organization/details-tabs/ViewOrganizationUserRentStationsRevenues.vue'
          ),
      },
      {
        name: 'ViewOrganizationUserRentStationsReports',
        path: 'rent-stations-reports',
        component: () =>
          import(
            '@/views/user/organization/details-tabs/ViewOrganizationUserRentStationsReports.vue'
          ),
      },
    ],
  },

  /* ----------------
      Rider
  ----------------- */
  {
    path: '/riders',
    name: 'Riders',
    component: () =>
      import(
        /* webpackChunkName: "riders" */ '@/views/user/rider/RiderIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/riders/:id',
    name: 'ViewRiderUser',
    component: () =>
      import(
        /* webpackChunkName: "rider-viewRiderUser" */
        '@/views/user/rider/RiderDetails'
      ),
    beforeEnter: ifAuthenticated,
    children: [
      {
        name: 'ViewRiderUserProfile',
        path: 'profile',
        component: () =>
          import(
            /* webpackChunkName: "rider-viewRiderUserProfile" */
            '@/views/user/rider/details-tabs/RiderProfile.vue'
          ),
      },
      {
        name: 'ViewRiderUserTrips',
        path: 'trips',
        component: () =>
          import(
            /* webpackChunkName: "rider-viewRiderUserTrips" */
            '@/views/user/rider/details-tabs/RiderTrips.vue'
          ),
      },
      {
        name: 'ViewRiderUserRents',
        path: 'rents',
        component: () =>
          import(
            /* webpackChunkName: "rider-viewRiderUserRents" */
            '@/views/user/rider/details-tabs/RiderRents.vue'
          ),
      },
      {
        name: 'ViewRiderUserCreditTransactions',
        path: 'credit-transactions',
        component: () =>
          import(
            /* webpackChunkName: "rider-viewRiderUserTransactions" */
            '@/views/user/rider/details-tabs/RiderCreditTransactions.vue'
          ),
      },
      {
        name: 'ViewRiderUserDebitTransactions',
        path: 'debit-transactions',
        component: () =>
          import(
            /* webpackChunkName: "rider-viewRiderUserTransactions" */
            '@/views/user/rider/details-tabs/RiderDebitTransactions.vue'
          ),
      },
      {
        name: 'ViewRiderUserPayments',
        path: 'payments',
        component: () =>
          import(
            /* webpackChunkName: "rider-viewRiderUserPayments" */
            '@/views/user/rider/details-tabs/RiderPayments.vue'
          ),
      },
      {
        name: 'ViewRiderUserReferrals',
        path: 'referrals',
        component: () =>
          import(
            /* webpackChunkName: "rider-viewRiderUserReferrals" */
            '@/views/user/rider/details-tabs/RiderReferrals.vue'
          ),
      },
      {
        //--riderVoucher list
        name: 'ViewRiderUserVouchers',
        path: 'vouchers',
        component: () =>
          import(
            /* webpackChunkName: "rider-viewRiderUserReferrals" */
            '@/views/user/rider/details-tabs/RiderVouchers.vue'
          ),
      },
      {
        name: 'ViewRiderUserPoints',
        path: 'points',
        component: () =>
          import(
            /* webpackChunkName: "rider-viewRiderUserPoints" */
            '@/views/user/rider/details-tabs/RiderPoints.vue'
          ),
      },
      {
        name: 'ViewRiderUserPass',
        path: 'pass',
        component: () =>
          import(
            /* webpackChunkName: "rider-viewRiderUserPass" */
            '@/views/user/rider/details-tabs/RiderPass.vue'
          ),
      },
      {
        name: 'ViewRiderUserPromo',
        path: 'promotions',
        component: () =>
          import(
            /* webpackChunkName: "rider-viewRiderUserPromotion" */
            '@/views/user/rider/details-tabs/RiderPromotion.vue'
          ),
      },
      {
        name: 'ViewRiderUserReports',
        path: 'reports',
        component: () =>
          import(
            /* webpackChunkName: "rider-viewRiderUserReports" */
            '@/views/user/rider/details-tabs/RiderReports.vue'
          ),
      },
      {
        name: 'ViewRiderUserLogs',
        path: 'logs',
        component: () =>
          import(
            /* webpackChunkName: "rider-viewRiderUserLogs" */
            '@/views/user/rider/details-tabs/RiderLogs.vue'
          ),
      },
      {
        name: 'ViewRiderUserCustomNotification',
        path: 'notifications',
        component: () =>
          import(
            /* webpackChunkName: "rider-viewRiderUserLogs" */
            '@/views/user/rider/details-tabs/RiderNotifications.vue'
          ),
      },
    ],
  },
  {
    path: '/blacklisted-riders',
    name: 'BlackListedRiders',
    component: () =>
      import(
        /* webpackChunkName: "riders" */ '@/views/user/rider/BlackListedRiderIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/delete-request-by-riders',
    name: 'DeleteRequestedRiders',
    component: () =>
      import(
        /* webpackChunkName: "riders" */ '@/views/user/rider/DeleteRequestedRiders.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/deleted-riders',
    name: 'DeletedRiders',
    component: () =>
      import(
        /* webpackChunkName: "riders" */ '@/views/user/rider/DeletedRiders.vue'
      ),
    beforeEnter: ifSuperAdmin,
  },

  /* ----------------
      Trips
  ----------------- */
  {
    path: '/trips',
    name: 'Trips',
    component: () =>
      import(
        /* webpackChunkName: "trip-index-v2" */ '@/views/trip/TripIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/tl',
    name: 'TL',
    component: () =>
      import(/* webpackChunkName: "trip-tl" */ '@/views/trip/TL.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/trips-v1',
    name: 'TripIndexV1',
    component: () =>
      import(
        /* webpackChunkName: "trip-index-v1" */ '@/views/trip/TripIndexV1.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/trips/:id',
    name: 'ViewTrip',
    component: () =>
      import(/* webpackChunkName: "trips" */ '@/views/trip/ViewTrip.vue'),
    beforeEnter: ifAuthenticated,
  },

  /* ----------------
    Vehicle Rentals
  ----------------- */
  {
    path: '/vehicle-rentals',
    name: 'VehicleRentals',
    component: () =>
      import(
        /* webpackChunkName: "vehicle-rental-index" */ '@/views/vehicle-rentals/VehicleRentalIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/vehicle-rentals/:id',
    name: 'VehicleRentalDetail',
    component: () =>
      import(
        /* webpackChunkName: "vehicle-rental-detail" */ '@/views/vehicle-rentals/VehicleRentalDetail.vue'
      ),
    beforeEnter: ifAuthenticated,
  },

  /* ----------------
      Transaction
  ----------------- */
  {
    path: '/revenues',
    name: 'RevenueIndex',
    component: () =>
      import(
        /* webpackChunkName: "revenue-index" */
        '@/views/transactions/RevenueIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/payments',
    name: 'PaymentIndex',
    component: () =>
      import(
        /* webpackChunkName: "payment-index" */
        '@/views/transactions/PaymentIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/trigger-payments',
    name: 'TriggerPaymentIndex',
    component: () =>
      import(
        /* webpackChunkName: "payment-index" */
        '@/views/transactions/TriggerPaymentIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/refund-requests',
    name: 'RefundRequestIndex',
    component: () =>
      import(
        /* webpackChunkName: "payment-index" */
        '@/views/transactions/RefundRequestIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  /* ----------------
      Promo
  ----------------- */
  {
    path: '/promos',
    name: 'PromotionIndex',
    component: () =>
      import(
        /* webpackChunkName: "promotion-index" */
        '@/views/promotion/PromotionIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/promos/:id',
    name: 'PromotionDetails',
    component: () =>
      import(
        /* webpackChunkName: "promotion-details" */
        '@/views/promotion/PromotionDetails.vue'
      ),
    beforeEnter: ifAuthenticated,
  },

  /* ----------------
      Pass
  ----------------- */
  {
    path: '/subscription',
    name: 'PassIndex',
    component: () =>
      import(/* webpackChunkName: "pass-index" */ '@/views/pass/PassIndex.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/subscription/:id',
    name: 'PassDetails',
    component: () =>
      import(
        /* webpackChunkName: "pass-details" */ '@/views/pass/PassDetails.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  /* ----------------
      Topup Offer
  ----------------- */
  {
    path: '/topup-offer',
    name: 'TopupOfferIndex',
    component: () => import('@/views/topup-offer/TopupOfferIndex.vue'),
    beforeEnter: ifAuthenticated,
  },

  /* ----------------
      Report
  ----------------- */
  {
    path: '/reports',
    name: 'Reports',
    component: () =>
      import(/* webpackChunkName: "reports" */ '@/views/report/Reports.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reports/:id',
    name: 'ReportDetails',
    component: () =>
      import(
        /* webpackChunkName: "report-details" */ '@/views/report/ReportDetails.vue'
      ),
    beforeEnter: ifAuthenticated,
  },

  /* ----------------
      ACL
  ----------------- */
  {
    path: '/roles',
    name: 'RoleIndex',
    component: () =>
      import(/* webpackChunkName: "roles" */ '@/views/acl/RoleIndex.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/permissions',
    name: 'PermissionIndex',
    component: () =>
      import(
        /* webpackChunkName: "roles" */
        '@/views/acl/PermissionIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },

  /* ----------------
      Iot Capabilities
  ----------------- */
  {
    path: '/iot-capabilities',
    name: 'IotCapabilitiesIndex',
    component: () =>
      import(
        /* webpackChunkName: "iot-capabilities" */ '@/views/iot-capabilities/IotCapabilitiesIndex.vue'
      ),
    beforeEnter: ifSuperAdmin,
  },

  /* ----------------
      Organization
  ----------------- */
  {
    path: '/organizations',
    name: 'OrganizationManagement',
    component: () =>
      import(
        /* webpackChunkName: "organizations" */
        // '@/views/organization/OrganizationsTest.vue'
        '@/views/organization/Organizations.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/organizations/:id',
    name: 'ViewOrganization',
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "organization-detail" */
        '@/views/organization/ViewOrganization.vue'
      ),
    children: [
      {
        name: 'ViewOrganizationProfile',
        path: 'profile',
        component: () =>
          import(
            /* webpackChunkName: "organization-profile" */
            '@/views/organization/ViewOrganizationProfile.vue'
          ),
      },
      {
        name: 'ViewOrganizationPricingPlan',
        path: 'pricing-plan',
        component: () =>
          import(
            /* webpackChunkName: "organization-profile" */
            '@/views/organization/ViewOrganizationPricingPlan.vue'
          ),
      },
      {
        name: 'ViewOrganizationServiceArea',
        path: 'service-areas',
        component: () =>
          import(
            /* webpackChunkName: "organization-profile" */
            '@/views/organization/ViewOrganizationServiceArea.vue'
          ),
      },
      {
        name: 'ViewOrganizationRents',
        path: 'rents',
        component: () =>
          import('@/views/organization/ViewOrganizationRents.vue'),
      },
      {
        name: 'ViewOrganizationSummary',
        path: 'summary',
        component: () =>
          import(
            /* webpackChunkName: "organization-summary" */
            '@/views/organization/ViewOrganizationSummary.vue'
          ),
      },
      {
        name: 'ViewOrganizationFleets',
        path: 'fleets',
        component: () =>
          import(
            /* webpackChunkName: "organization-fleets" */
            '@/views/organization/ViewOrganizationFleets.vue'
          ),
      },
      {
        name: 'ViewOrganizationVehicles',
        path: 'vehicles',
        component: () =>
          import(
            /* webpackChunkName: "organization-vehicles" */
            '@/views/organization/ViewOrganizationVehicles.vue'
          ),
      },
      {
        name: 'ViewOrganizationPayouts',
        path: 'payouts',
        component: () =>
          import(
            /* webpackChunkName: "organization-payouts" */
            '@/views/organization/ViewOrganizationPayouts.vue'
          ),
      },
      {
        name: 'ViewOrganizationLocations',
        path: 'locations',
        component: () =>
          import(
            /* webpackChunkName: "organization-locations" */
            '@/views/organization/ViewOrganizationLocations.vue'
          ),
      },
      {
        name: 'ViewOrganizationTasks',
        path: 'tasks',
        component: () =>
          import(
            /* webpackChunkName: "organization-tasks" */
            '@/views/organization/ViewOrganizationTasks.vue'
          ),
      },
      {
        name: 'ViewOrganizationUsers',
        path: 'users',
        component: () =>
          import(
            /* webpackChunkName: "organization-users" */
            '@/views/organization/ViewOrganizationUsers.vue'
          ),
      },
      {
        name: 'ViewOrganizationRevenues',
        path: 'revenues',
        component: () =>
          import(
            /* webpackChunkName: "organization-revenues" */
            '@/views/organization/ViewOrganizationRevenues.vue'
          ),
      },
      {
        name: 'ViewOrganizationPayments',
        path: 'payments',
        component: () =>
          import(
            /* webpackChunkName: "organization-revenues" */
            '@/views/organization/ViewOrganizationPayments.vue'
          ),
      },
      {
        name: 'ViewOrganizationRiders',
        path: 'riders',
        component: () =>
          import(
            /* webpackChunkName: "organization-riders" */
            '@/views/organization/ViewOrganizationRiders.vue'
          ),
      },
      {
        name: 'ViewOrganizationSubscriptionInvoices',
        path: 'subscription-invoices',
        component: () =>
          import(
            /* webpackChunkName: "organization-subscription-invoices" */
            '@/views/organization/details/SubscriptionInvoices.vue'
          ),
      },
      {
        name: 'ViewOrganizationRiders',
        path: 'subscription-transactions',
        component: () =>
          import(
            /* webpackChunkName: "organization-subscription-transactions" */
            '@/views/organization/details/SubscriptionTransactions.vue'
          ),
      },
    ],
  },

  /* ----------------
      Settings
  ----------------- */
  {
    path: '/settings',
    name: 'Settings',
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "settings-Settings" */
        '@/views/settings/Settings.vue'
      ),
    children: [
      {
        name: 'RiderSettings',
        path: 'rider',
        component: () => import('@/views/settings/RideSettings'),
      },
      {
        name: 'RiderAppSettings',
        path: 'rider-app',
        component: () => import('@/views/settings/RiderAppSettings'),
      },
      {
        name: 'SecuritySettings',
        path: 'security',
        component: () =>
          import(
            /* webpackChunkName: "settings-Basic" */
            '@/views/settings/SecuritySettings.vue'
          ),
      },
      {
        name: 'EmailSettings',
        path: 'email',
        component: () =>
          import(
            /* webpackChunkName: "settings-EmailSettings" */
            '@/views/settings/EmailSettings.vue'
          ),
      },
      {
        name: 'TripSettings',
        path: 'trip',
        component: () =>
          import(
            /* webpackChunkName: "settings-VehicleSettings" */
            '@/views/settings/TripSettings.vue'
          ),
      },
      {
        name: 'RentSettings',
        path: 'rent',
        component: () =>
          import(
            /* webpackChunkName: "settings-VehicleSettings" */
            '@/views/settings/RentSettings.vue'
          ),
      },
      {
        name: 'IotSettings',
        path: 'iot',
        component: () =>
          import(
            /* webpackChunkName: "settings-VehicleSettings" */
            '@/views/settings/IotSettings.vue'
          ),
      },
      {
        name: 'VehicleSettings',
        path: 'vehicle',
        component: () =>
          import(
            /* webpackChunkName: "settings-VehicleSettings" */
            '@/views/settings/VehicleSettings.vue'
          ),
      },
      {
        name: 'EventSettings',
        path: 'event',
        component: () =>
          import(
            /* webpackChunkName: "settings-EventSettings" */
            '@/views/settings/EventSettings.vue'
          ),
      },
      {
        name: 'PaymentSettings',
        path: 'payment',
        component: () =>
          import(
            /* webpackChunkName: "settings-PaymentSettings" */
            '@/views/settings/PaymentSettings.vue'
          ),
      },
      {
        name: 'ScheduleSettings',
        path: 'schedule',
        component: () =>
          import(
            /* webpackChunkName: "settings-ScheduleSettings" */
            '@/views/settings/ScheduleSettings.vue'
          ),
      },
      {
        name: 'QuizSettings',
        path: 'quiz',
        component: () =>
          import(
            /* webpackChunkName: "settings-ScheduleSettings" */
            '@/views/settings/QuizSettings.vue'
          ),
      },
      {
        name: 'PointSettings',
        path: 'point',
        component: () =>
          import(
            /* webpackChunkName: "settings-PointSettings" */
            '@/views/settings/PointSettings.vue'
          ),
      },
      {
        name: 'GeneralSettings',
        path: 'general',
        component: () =>
          import(
            /* webpackChunkName: "settings-GeneralSettings" */
            '@/views/settings/GeneralSettings.vue'
          ),
      },
      {
        name: 'OperatorSettings',
        path: 'operator',
        component: () =>
          import(
            /* webpackChunkName: "settings-OperatorSettings" */
            '@/views/settings/OperatorSettings.vue'
          ),
      },
      {
        name: 'ProfileSettings',
        path: 'profile',
        component: () =>
          import(
            /* webpackChunkName: "settings-ScheduleSettings" */
            '@/views/settings/ProfileSettings.vue'
          ),
      },
    ],
  },

  /* ----------------
      Live Map
  ----------------- */

  {
    path: '/live-map',
    name: 'LiveMapOldIndex',
    component: () =>
      import(
        /* webpackChunkName: "LiveMap" */ '@/views/LiveMap/LiveMapOldIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/live-map/:id',
    name: 'LiveMap',
    component: () =>
      import(
        /* webpackChunkName: "LiveMap" */ '@/views/LiveMap/LiveMapOldIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/vehicle-in-map/',
    name: 'ViewVehicleInMap',
    component: () =>
      import(
        /* webpackChunkName: "task-index" */

        '@/views/LiveMap/ViewVehicleInMap.vue'
      ),
  },
  // {
  //   path: '/live-map/new',
  //   name: 'LiveMapNewIndex',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "LiveMap" */ '@/views/LiveMap/LiveMapNewIndex.vue'
  //     ),
  //   beforeEnter: ifAuthenticated,
  // },
  // {
  //   path: '/live-map/new/:id',
  //   name: 'LiveMap',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "LiveMap" */ '@/views/LiveMap/LiveMapNewIndex.vue'
  //     ),
  //   beforeEnter: ifAuthenticated,
  // },
  // {
  //   path: '/live-map/:id',
  //   name: 'LiveMap',
  //   component: () =>
  //     import(/* webpackChunkName: "LiveMap" */ '@/views/LiveMap/index.vue'),
  //   beforeEnter: ifAuthenticated,
  // },

  /* ----------------
      Fleet
  ----------------- */
  {
    path: '/fleets',
    name: 'Fleets',
    component: () =>
      import(
        /* webpackChunkName: "fleet-index" */
        '@/views/fleet/FleetIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/fleets/:id',
    name: 'ViewFleet',
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "fleet-details" */
        '@/views/fleet/FleetDetails.vue'
      ),
    children: [
      {
        name: 'ViewFleetSummary',
        path: 'summary',
        component: () =>
          import(
            /* webpackChunkName: "fleet-details-summary" */
            '@/views/fleet/details-tabs/Summary.vue'
          ),
      },
      {
        name: 'ViewFleetProfile',
        path: 'profile',
        component: () =>
          import(
            /* webpackChunkName: "fleet-details-profile" */
            '@/views/fleet/details-tabs/Profile.vue'
          ),
      },
      {
        name: 'ViewFleetVehicles',
        path: 'vehicles',
        component: () =>
          import(
            /* webpackChunkName: "fleet-details-vehicle" */
            '@/views/fleet/details-tabs/Vehicles.vue'
          ),
      },
      {
        name: 'ViewFleetRiders',
        path: 'riders',
        component: () =>
          import(
            /* webpackChunkName: "fleet-details-rides" */
            '@/views/fleet/details-tabs/Riders.vue'
          ),
      },
      {
        name: 'ViewFleetRides',
        path: 'rides',
        component: () =>
          import(
            /* webpackChunkName: "fleet-details-rides" */
            '@/views/fleet/details-tabs/Rides.vue'
          ),
      },
      {
        name: 'ViewFleetPayments',
        path: 'payments',
        component: () =>
          import(
            /* webpackChunkName: "fleet-details-payments" */
            '@/views/fleet/details-tabs/Payments.vue'
          ),
      },
      {
        name: 'ViewFleetRevenues',
        path: 'revenues',
        component: () =>
          import(
            /* webpackChunkName: "fleet-details-revenues" */
            '@/views/fleet/details-tabs/Revenues.vue'
          ),
      },
      {
        name: 'ViewFleetPromotions',
        path: 'promotions',
        component: () =>
          import(
            /* webpackChunkName: "fleet-details-promotions" */
            '@/views/fleet/details-tabs/Promotions.vue'
          ),
      },
      {
        name: 'ViewFleetReports',
        path: 'reports',
        component: () =>
          import(
            /* webpackChunkName: "fleet-details-reports" */
            '@/views/fleet/details-tabs/Reports.vue'
          ),
      },
    ],
  },

  /* ----------------
      Marketplaces
  ----------------- */
  {
    path: '/marketplaces/orders',
    name: 'MarketplaceIndex',
    component: () =>
      import(
        /* webpackChunkName: "fleet-index" */
        '@/views/marketplace/MarketplaceIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/marketplaces/orders/:id',
    name: 'MarketplaceDetails',
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "marketplace-details" */
        '@/views/marketplace/MarketplaceDetails.vue'
      ),
    children: [
      {
        name: 'ViewMarketplaceProfile',
        path: 'profile',
        component: () =>
          import('@/views/marketplace/details-tabs/ViewMarketplaceProfile.vue'),
      },
      {
        name: 'ViewMarketplaceVehicles',
        path: 'vehicles',
        component: () =>
          import(
            '@/views/marketplace/details-tabs/ViewMarketplaceVehicles.vue'
          ),
      },
      {
        name: 'ViewMarketplacePurchase',
        path: 'purchase',
        component: () =>
          import(
            '@/views/marketplace/details-tabs/ViewMarketplacePurchase.vue'
          ),
      },
      {
        name: 'ViewMarketplaceRevenues',
        path: 'revenues',
        component: () =>
          import(
            '@/views/marketplace/details-tabs/ViewMarketplaceRevenues.vue'
          ),
      },
      {
        name: 'ViewMarketplaceBuyers',
        path: 'buyers',
        component: () =>
          import('@/views/marketplace/details-tabs/ViewMarketplaceBuyers.vue'),
      },
      {
        name: 'ViewMarketplacePayouts',
        path: 'payouts',
        component: () =>
          import('@/views/marketplace/details-tabs/ViewMarketplacePayouts.vue'),
      },
    ],
  },
  {
    path: '/marketplaces/buyers',
    name: 'MarketplaceBuyerIndex',
    component: () => import('@/views/marketplace/MarketplaceBuyerIndex.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/marketplaces/buyers/:id',
    name: 'ViewBuyerDetails',
    component: () => import('@/views/marketplace/buyers/BuyerDetails.vue'),
    beforeEnter: ifAuthenticated,
    children: [
      {
        name: 'ViewBuyerProfile',
        path: 'profile',
        component: () =>
          import('@/views/marketplace/buyers/details-tab/BuyerProfile.vue'),
      },
      {
        name: 'ViewBuyerOrders',
        path: 'orders',
        component: () =>
          import('@/views/marketplace/buyers/details-tab/BuyerOrders.vue'),
      },
      {
        name: 'ViewBuyerVehicles',
        path: 'vehicles',
        component: () =>
          import('@/views/marketplace/buyers/details-tab/BuyerVehicles.vue'),
      },
    ],
  },
  {
    path: '/marketplaces/payouts',
    name: 'MarketplacePayoutIndex',
    component: () => import('@/views/marketplace/MarketplacePayoutIndex.vue'),
    beforeEnter: ifAuthenticated,
  },

  /* ----------------
      Mail Templates
  ---------------- */
  {
    path: '/mails',
    name: 'MailIndex',
    component: () =>
      import(
        /* webpackChunkName: "mail-index" */
        '@/views/mail/Index.vue'
      ),
    beforeEnter: ifAuthenticated,
    children: [
      {
        name: 'ViewMailList',
        path: '/',
        beforeEnter: ifAuthenticated,
        component: () =>
          import(
            /* webpackChunkName: "mails-ViewMailList" */
            '@/views/mail/MailTemplateList.vue'
          ),
      },
    ],
  },

  {
    name: 'ViewMailTemplate',
    path: '/mails-template/:id',
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "mails-ViewMailTemplate" */
        '@/views/mail/MailTemplateDetails.vue'
      ),
  },

  /* ----------------
      Voucher Management
  ----------------- */
  {
    path: '/voucher-management',
    name: 'VoucherIndex',
    component: () =>
      import(
        /* webpackChunkName: "voucher-management-index" */
        '@/views/voucher-management/VoucherIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },

  /* ----------------
     Voucher Management
 ----------------- */
  {
    path: '/voucher-management/:id',
    name: 'VoucherDetails',
    component: () =>
      import(
        /* webpackChunkName: "voucher-management-details" */
        '@/views/voucher-management/details/DetailsIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },

  /* ----------------
    Marketing Campaign Management
  ----------------- */
  {
    path: '/marketing-campaigns',
    name: 'MarketingCampaignIndex',
    component: () =>
      import(
        /* webpackChunkName: "marketing-campaign-index" */
        '@/views/marketing-campaigns/MarketingIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/marketing-campaigns/:id',
    name: 'MarketingCampaignDetailsIndex',
    component: () =>
      import(
        /* webpackChunkName: "marketing-campaign-details" */
        '@/views/marketing-campaigns/details/MarketingCampaignDetailsIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/marketing-campaigns-notify/:id',
    name: 'MarketingCampaignNotifyDetailsIndex',
    component: () =>
      import(
        /* webpackChunkName: "marketing-campaign-details" */
        '@/views/marketing-campaigns/details/MarketingCampaignNotifyDetailsIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },

  /* ----------------
    Notification Logs
  ----------------- */
  {
    path: '/notifications',
    name: 'NotificationIndex',
    component: () =>
      import(
        /* webpackChunkName: "audit-logs-index" */
        '@/views/notification/NotificationIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  /* ----------------
    what's new
  ----------------- */
  {
    path: '/whats-new',
    name: 'WhatsNewIndex',
    component: () =>
      import(
        /* webpackChunkName: "audit-logs-index" */
        '@/views/whats-new/WhatsNewIndex.vue'
      ),
    beforeEnter: ifSuperAdmin,
  },

  /* ----------------
    Audit Logs
  ----------------- */
  {
    path: '/event-logs',
    name: 'AuditLogsIndex',
    component: () =>
      import(
        /* webpackChunkName: "audit-logs-index" */
        '@/views/audit-logs/AuditLogsIndex.vue'
      ),
    beforeEnter: ifAuthenticated,
  },

  /* ----------------
      Task
  ----------------- */
  {
    path: '/tasks',
    name: 'TaskIndex',
    component: () =>
      import(
        /* webpackChunkName: "task-index" */
        '@/views/task/Index.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/tasks/:id',
    name: 'ViewTaskDetails',
    component: () =>
      import(
        /* webpackChunkName: "task-index" */

        '@/views/task/TaskDetails.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
]

//--dynamically switch login component code here depend on website

if (window.location.host.split('-')[0] === 'bemz') {
  routes[6] = {
    path: '/login',
    name: 'BemzLogin',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/auth/BemzLogin.vue'),
    beforeEnter: ifNotAuthenticated,
  }
} else if (window.location.host.split('-')[0] === 'breeze') {
  routes[6] = {
    path: '/login',
    name: 'BreezeLogin',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/auth/BreezeLogin.vue'),
    beforeEnter: ifNotAuthenticated,
  }
} else if (window.location.host.split('-')[0] === 'despii') {
  routes[6] = {
    path: '/login',
    name: 'DespiiLogin',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/auth/DespiiLogin.vue'),
    beforeEnter: ifNotAuthenticated,
  }
} else if (window.location.host.split('.').includes('skootel')) {
  routes[6] = {
    path: '/login',
    name: 'SkootelLogin',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/auth/SkootelLogin.vue'),
    beforeEnter: ifNotAuthenticated,
  }
} else {
  routes[6] = {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue'),
    beforeEnter: ifNotAuthenticated,
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // to, from, savedPosition
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 500)
    })
  },
})

export default router
