export default {
  namespaced: true,

  state: {
    headline: '',
    isPopupShow: false,
    isFullView: false,
    isLoading: false,
    isSmallScreen: false,
    data: [],
  },
  getters: {
    getHeadline(state) {
      return state.headline
    },
    getPopViewStatus(state) {
      return state.isPopupShow
    },
    getFullViewStatus(state) {
      return state.isFullView
    },
    getReqStatus(state) {
      return state.isLoading
    },

    getSmallScreenStatus(state) {
      return state.isSmallScreen
    },

    getData(state) {
      return state.data
    },
  },
  mutations: {
    SET_HEADLINE(state, value) {
      state.headline = value
    },
    SET_POPUP_VIEW_STATUS(state, value) {
      state.isPopupShow = value
    },
    SET_FULL_VIEW_STATUS(state, value) {
      state.isFullView = value
    },
    SET_SMALL_SCREEN_STATUS(state, value) {
      state.isSmallScreen = value
    },
    SET_REQ_STATUS(state, value) {
      state.isLoading = value
    },
    INJECT_DATA(state, payload) {
      state.data.unshift(payload)
    },
    REMOVE_DATA_ITEM(state, index) {
      state.data.splice(index, 1)
    },
  },
}
