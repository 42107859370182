export const SocketConfig = {
  configs: () => {
    return {
      debug: true,
      reconnectInterval: 3000,
      maxReconnectInterval: 30000,
      maxReconnectAttempts: 5,
      timeoutInterval: 5400,
      automaticOpen: true,
    }
  },
  baseUrl: process.env.VUE_APP_WS_API_URL + 'dashboard/',
  baseUrlV1: process.env.VUE_APP_WS_API_URL + 'v1/',
  channels: {
    notifications: 'notifications/',
    bikeUpdates: 'bike-updates/',
    bikeNotifications: 'bike-notifications/',
    userInspection: 'user/inspection/',
    userWorkflow: 'user/workflow/',
    tripUpdates: 'trip-updates/',
  },
}
